// react
import { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
// style and icon
import "./Layout.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightFromBracket, faBars } from "@fortawesome/free-solid-svg-icons";
import { faQuestionCircle, faUser } from "@fortawesome/free-regular-svg-icons";
// component
import BackToTop from "./BackToTop";
import LoginBox from "./LoginBox";
import SearchBox from "./SearchBox";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// utils
import { AppState, Category, UserProfile } from "../utils/models";
import { useClearStorage } from "../utils/shared";
import { ToastContainer } from "react-toastify";
import React from "react";
import BootstrapTooltip from "./BootstrapTooltip";

type LayoutProps = {
  currentUser: UserProfile | null;
  channels: Category[] | [];
};

const Layout: React.FC<LayoutProps> = ({ currentUser, channels }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showLoginPopup, setShowLoginPopup] = useState<boolean>(false);
  const [logoutConfirmPopupOpen, setLogoutConfirmPopupOpen] = useState(false);
  const clearStorage = useClearStorage();

  const handleLogoutConfirm = () => {
    setLogoutConfirmPopupOpen(false);
    clearStorage();
    navigate('/');
  };
  const handleLogoutCancel = () => {
    setLogoutConfirmPopupOpen(false);
  };

  const handleLoginButtonClick = async () => {
    if (currentUser) {
      navigate('/profile');
    } else {
      setShowLoginPopup(!showLoginPopup) // Guest
    }
  }
  const logout = async () => {
    setLogoutConfirmPopupOpen(true);
  }

  let nav: any = [];
  if (channels){
    nav = [
      {
        title: t('home'),
        url: '/',
        color: 'orange',
      },
      ...channels.map((channel) => (
          {
            title: channel.title,
            subtitle: channel.subtitle,
            url: `/channel/${channel.slug}`,
            color: channel.color,
          }
      ))
    ];
  }

  return (
    <>
      <header className="navbar navbar-expand-md bg-white">
        <div className="container">
          <a className="navbar-brand" href="/">
            <img src={`${process.env.PUBLIC_URL}/images/logo.png`} alt="BYOV" className="d-block" style={{ height: '100px' }} />
          </a>
          <div className="collapse navbar-collapse justify-content-end" id="header-nav">
            <ul className="navbar-nav">
              {nav.map((nav: any, i: number) => (
                <li className="nav-item" key={i}>
                  <BootstrapTooltip title={nav.subtitle} color={nav.color}>
                    <a className="nav-link" color={nav.color} href={nav.url}>
                      {nav.title}
                    </a>
                  </BootstrapTooltip>
                </li>
              ))}
            </ul>
          </div>
          <div className="header-icons">
            <ul className="navbar-nav">
              <li className="nav-item">
                <div className="nav-link" style={{ padding: 0 }} color="orange">
                  <SearchBox />
                </div>
              </li>
              {currentUser && <li className="nav-item">
                <BootstrapTooltip title="Download user's guide" color="orange">
                  <a className="nav-link" color="orange" href="/edflix_users_guide.pdf" target="_blank">
                    <FontAwesomeIcon icon={faQuestionCircle} />
                  </a>
                </BootstrapTooltip>
              </li>}
              <li className="nav-item">
                <div className="login-popup-trigger">
                  <BootstrapTooltip title={currentUser ? "My user profile" : "Login"} color="orange">
                    <div className="nav-link" onClick={handleLoginButtonClick}>
                      <FontAwesomeIcon icon={faUser} />
                    </div>
                  </BootstrapTooltip>
                  <div className={`login-popup-container ${showLoginPopup ? 'show' : ''}`}>
                    <div className="login-popup-overlay" onClick={() => setShowLoginPopup(false)} />
                    <LoginBox onClose={() => setShowLoginPopup(false)}/>
                  </div>
                </div>
              </li>
              {currentUser && <li className="nav-item">
                <BootstrapTooltip title="Logout" color="orange">
                  <div className="nav-link clickable" onClick={() => logout()}>
                    <FontAwesomeIcon icon={faArrowRightFromBracket} />
                  </div>
                </BootstrapTooltip>
              </li>}
              <li className="nav-item d-md-none">
                <a className="nav-link" href="/"
                  data-bs-toggle="collapse"
                  data-bs-target="#header-nav"
                  aria-controls="header-nav"
                  aria-expanded="false"
                  aria-label="Toggle navigation">
                  <FontAwesomeIcon icon={faBars} />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </header>
      <Dialog
        open={logoutConfirmPopupOpen}
        onClose={handleLogoutCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle sx={{ m: 0, p: 4 }}  id="alert-dialog-title">
          {"Are you sure you want to logout?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ m: 2 }} >
          <Button onClick={handleLogoutConfirm}>Logout</Button>
          <Button onClick={handleLogoutCancel}>Cancel</Button>
        </DialogActions>
      </Dialog>

      <ToastContainer/>

      <Outlet context={{ channels }} />

      <BackToTop />

      {/*<footer>*/}
      {/*  <div className="container">*/}
      {/*    <hr />*/}
      {/*    <div className="wrapper">*/}
      {/*      <div className="left">*/}
      {/*        <div className="logo-container">*/}
      {/*          <img src={`${process.env.PUBLIC_URL}/images/logo.png`} alt="EdFlix" />*/}
      {/*          <img src={`${process.env.PUBLIC_URL}/images/evangel-college-logo.png`} alt="Evangel College" className="d-md-none" />*/}
      {/*        </div>*/}
      {/*        <div className="link-container pt-4">*/}
      {/*          <a href="https://www.evangel.edu.hk/e_index.php" className="btn btn-primary btn-sm" target="_blank">*/}
      {/*            {t('school_website')}*/}
      {/*          </a>*/}
      {/*          <a href="https://evangelcollege.smugmug.com/" className="btn btn-primary btn-sm" target="_blank">*/}
      {/*            {t('photo_gallery')}*/}
      {/*          </a>*/}
      {/*        </div>*/}
      {/*      </div>*/}

      {/*      <div className="right">*/}
      {/*        <img src={`${process.env.PUBLIC_URL}/images/evangel-college-logo.png`} alt="Evangel College" className="d-none d-md-block" />*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}

      {/*  <div className="copyright py-2 mt-3 mt-md-5">*/}
      {/*    <div className="container text-center">*/}
      {/*      <small>*/}
      {/*        {t('copyright', { year: new Date().getFullYear() })}*/}
      {/*      </small>*/}
      {/*    </div>*/}
      {/*  </div>*/}


      {/*</footer>*/}
    </>
  );
}

const mapStateToProps = (state: AppState) => ({
  currentUser: state.currentUser,
  channels: state.channels
});

export default connect(mapStateToProps)(Layout);
