// React
import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
// style 
import "./Search.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
// component
import { Checkbox, FormControlLabel } from "@mui/material";
// utils 
import { AppState, Category, Tag } from "../utils/models";
import {toast} from "react-toastify";

type SearchProps = {
  channels: Category[] | [];
  tags: Tag[] | [];
};

const Search: React.FC<SearchProps> = ({ channels, tags }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const minDateStr = '2006-01-01';
  const todayDate = new Date();
  const todayDateStr = todayDate.toISOString().slice(0, 10);

  // filter
  const [keywords, setKeywords] = useState<string>('');
  const [fromDateStr, setFromDateStr] = useState<string>('');
  const [toDateStr, setToDateStr] = useState<string>('');
  const [checkboxes, setCheckboxes] = useState<boolean[][]>([]); // for UI effect
  const [selectedTags, setSelectedTags] = useState<number[]>([]);

  useEffect(() => {
    if (!channels) {
      return;
    }

    // Array of arrays of booleans, each array of booleans represents a channel
    setCheckboxes(channels.map(v => [...Array(v.children.length)].fill(false)))
  }, [channels]);

  const handleTagChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const tagId = parseInt(event.target.value);
    if (event.target.checked) {
      // Add the selected tag to the array
      setSelectedTags([...selectedTags, tagId]);
    } else {
      // Remove the deselected tag from the array
      setSelectedTags(selectedTags.filter((id) => id !== tagId));
    }
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setKeywords(event.target.value);
  };

  const handleFromDateChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFromDateStr(event.target.value);
  };

  const handleToDateChange = (event: ChangeEvent<HTMLInputElement>) => {
    setToDateStr(event.target.value);
  };

  const onChannelCheckboxChange = (checked: boolean, parentIndex: number) => {
    let _checkboxes = [...checkboxes];
    _checkboxes[parentIndex] = checkboxes[parentIndex].fill(checked);
    setCheckboxes(_checkboxes);
  }

  // A function component that represents subchannel checkboxes under a given channel
  const SubCategories: React.FC<{
    subChannels: Category[],
    parentIndex: number,
  }> = ({
    subChannels,
    parentIndex,
  }) => {
      const onSubChannelCheckboxChange = (checked: boolean, index: number, subChannelId: number) => {
        let _checkboxes = [...checkboxes];
        _checkboxes[parentIndex][index] = checked;
        setCheckboxes(_checkboxes);
      }

      return (
        <div className="sub-categories-container">
          {subChannels?.map((subChannel, subChannelIndex) => (
            <FormControlLabel
              key={subChannelIndex}
              label={subChannel.title}
              control={
                <Checkbox
                  color="default"
                  checked={checkboxes[parentIndex]?.[subChannelIndex]}
                  onChange={
                    (_, checked) =>
                        onSubChannelCheckboxChange(checked, subChannelIndex, subChannel.id)
                  }
                />
              }/>
          ))}
        </div>
      );
    }

  const onReset = (e: any) => {
    setKeywords('');
    setSelectedTags([]);
    setFromDateStr('');
    setToDateStr('');
    checkboxes.forEach((checkbox, i) => {
      onChannelCheckboxChange(false, i);
    });
  }

  const onSubmit = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    let categoryIds: number[] = [];

    // Get the selected sub-channels
    for (let i = 0; i < checkboxes.length; i++) {
        for (let j = 0; j < checkboxes[i].length; j++) {
            if (checkboxes[i][j]) {
              categoryIds.push(channels[i].children[j].id);
            }
        }
    }

    if (
      !keywords &&
      categoryIds.length === 0 &&
      selectedTags.length === 0 &&
      !fromDateStr &&
      !toDateStr
    ) {
      // Disallow empty advanced search
      return;
    }

    // Check if the selected date is valid
    const minDate = new Date(minDateStr);
    let errorArray = [];

    if (fromDateStr) {
      if (new Date(fromDateStr) < minDate || new Date(fromDateStr) > todayDate) {
        setFromDateStr(minDateStr); // set the input min valid data
        errorArray.push('Invalid from-date.');
      }
    }
    if (toDateStr) {
      if (new Date(toDateStr) < minDate || new Date(toDateStr) > todayDate) {
        setToDateStr(todayDateStr); // set the input max valid data
        errorArray.push('Invalid to-date.');
      }
    }
    if (fromDateStr && toDateStr) {
      if (new Date(fromDateStr) > new Date(toDateStr)){
        errorArray.push('Invalid from-date.');
        errorArray.push('Invalid to-date.');
      }
    }
    if (errorArray.length > 0) {
      toast.error(errorArray.join('\n'));
      return;
    }

    // Start search
    let filter = {
      keywords: keywords,
      categoryIds: categoryIds,
      tagIds: selectedTags,
      fromDateStr: fromDateStr,
      toDateStr: toDateStr,
    };
    let serializedFilter = encodeURIComponent(JSON.stringify(filter));

    navigate(`/search/${serializedFilter}`, {
      state: {
        keywords: keywords || undefined,
        categoryIds: categoryIds.length > 0 ? categoryIds : undefined,
        tagIds: selectedTags.length > 0 ? selectedTags : undefined,
        fromDate: fromDateStr ? new Date(fromDateStr) : undefined,
        toDate: toDateStr ? new Date(toDateStr) : undefined,
      }
    })
  };

  return (
    <div id="page-search">
      <div className="container">
        <div className="title text-orange">
          <FontAwesomeIcon icon={faSearch} className="pe-3" />
          {t('advanced_search')}
        </div>

        <div className="input-container">
          <div className="icon">
            {t('keyword')}
          </div>
          <input type="search" value={keywords} onChange={handleSearchChange} />
        </div>

        <div className="row mt-3">
          <div className="col mb-3 mb-md-0">
            <div className="input-container">
              <div className="icon">
                {t('from_date')}
              </div>
              <input 
                type="date"
                value={fromDateStr}
                min={minDateStr}
                max={todayDateStr}
                onChange={handleFromDateChange}
              />
            </div>
          </div>

          <div className="col">
            <div className="input-container">
              <div className="icon" style={{ minWidth: "6em" }}>
                {t('to_date')}
              </div>
              <input
                type="date"
                value={toDateStr}
                min={minDateStr}
                max={todayDateStr}
                onChange={handleToDateChange}  />
            </div>
          </div>
        </div>

        <div className="input-container mt-3">
          <div className="icon w-100 justify-content-start">
            {t('category')}
          </div>
        </div>
        {channels?.map((channel, channelIndex) => (
          <div className="checkbox-container" key={channelIndex}>
            <FormControlLabel
              className="master-category"
              label={channel.title}
              control={
                <Checkbox
                  color="default"
                  checked={!checkboxes[channelIndex]?.includes(false)}
                  indeterminate={checkboxes[channelIndex]?.includes(true) && checkboxes[channelIndex]?.includes(false)}
                  onChange={(_, checked) => onChannelCheckboxChange(checked, channelIndex)}
                />
              } />

            <SubCategories
              parentIndex={channelIndex}
              subChannels={channel.children}/>
          </div>
        ))}

        <div className="input-container mt-3">
          <div className="icon w-100 justify-content-start">
            {t('tag')}
          </div>
        </div>
        <div className="checkbox-container">
          {tags.map((tag, i) => (
            <FormControlLabel
              key={i}
              label={`#${tag.name}`}
              control={
                <Checkbox
                  color="default"
                  value={tag.id}
                  checked={selectedTags.includes(tag.id)}
                  onChange={handleTagChange}
                />
              }
            />
          ))}
        </div>

        <div className="mt-4 text-md-end">
          <div
            className="btn btn-secondary d-block d-md-inline-block"
            onClick={onReset}>
            {t('button.reset')}
          </div>
          <div
            className="btn btn-orange ms-md-3 my-2 my-md-0 d-block d-md-inline-block"
            onClick={onSubmit}>
            {t('button.search')}
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: AppState) => ({
  channels: state.channels,
  tags: state.tags,
});

export default connect(mapStateToProps)(Search);
