// Import React hooks and components
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Import styles and utilities
import './Channel.scss';
import { commonSwiperSettings } from '../utils/shared';
import { Category } from '../utils/models';
import { getChannelInfo } from '../utils/endpoints';

// Import components
import Banner from '../components/Banner';
import InfoCard from '../components/InfoCard';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';

// Import FontAwesome icons
import Star from '../components/Star';
import { Helmet } from 'react-helmet';

const Channel: React.FC = () => {
  const { slug } = useParams();
  const { t } = useTranslation();
  const [channel, setChannel] = useState<Category>();
  const [seoSubChannels, setSeoSubChannels] = useState<string>('');

  const bannerImg: { [key: string]: string } = {
    'channel-1': '/images/banner/channels/channel-banner-blue.png',
    'channel-2': '/images/banner/channels/channel-banner-green.png',
    'channel-3': '/images/banner/channels/channel-banner-orange.png',
    'channel-4': '/images/banner/channels/channel-banner-purple.png',
  };
  let defaultChannelBanner = slug ? 
    bannerImg[slug] :
    '/images/banner/channels/channel-banner-blue.png';

  const handleFetch = async () => {
    if (slug) {
      const _channel = await getChannelInfo(slug);
      setChannel(_channel);
      document.title = [
          _channel?.title || '',
          _channel?.subtitle || '',
          'EdFlix'
      ].filter(Boolean).join(' | ');
      setSeoSubChannels(_channel?.children?.map((el:Category) => {return el?.title}).join(', ') || '');
    }
  }

  useEffect(() => {
    handleFetch();
  }, []);

  return channel ? (
    <div id="page-channel" color={channel.color}>
      <Helmet>
        <title>{document.title}</title>
        <meta name="description" content={`${document.title} ${channel?.subtitle}`} />
        <meta property="og:title" content={channel?.title} />
      </Helmet>
      <Banner img={channel.banner || defaultChannelBanner} className='sub-page-banner'>
        <div className={`text-container text-${channel.color}`}>
          <div className='banner-title'>
            <span className='d-inline-block me-3'>{channel.title}</span>
            <br/>
            <b>{channel.subtitle}</b>
          </div>
        </div>
      </Banner>

      <div className="container">
        {channel.children.map((subChannel, j) => (
          <div key={j} className="sub-channel-container mt-4 mt-md-5">
            <div className={`title bg-${subChannel.color}`}>
              <Star color={subChannel.color} reverse />
              <div className="text-container">
                <small>{t('sub_channel')}</small>
                <b className='d-block mt-1'>{subChannel.title}</b>
              </div>
            </div>

            <div className="content py-4">
              <div className="folders">
                <Swiper navigation={true} modules={[Navigation]} {...commonSwiperSettings}>
                  {subChannel.children
                    .map((folder, i) => (
                      <SwiperSlide key={i}>
                        <InfoCard
                          color={folder.color}
                          title={folder.title}
                          description={folder.alternative_title}
                          cardBackground={folder?.videos ? folder?.videos[0]?.landscape_thumbnail?.path : undefined}
                          cardBackgroundPortrait={folder?.videos ? folder?.videos[0]?.portrait_thumbnail?.path : undefined}
                          url={`/channel/${slug}/${folder.slug}`}
                          target="_self"
                        />
                      </SwiperSlide>
                    ))}
                </Swiper>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  ) : <></>;
}

export default Channel;
