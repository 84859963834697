import { useMemo } from "react";
import "./VideoStrip.scss";
import InfoCard from "./InfoCard";
import { Video } from "../utils/models";
import { commonSwiperSettings, mapVideosToPreviews } from "../utils/shared";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from 'swiper/modules';

export interface VideoStripProps {
  videoList: Video[];
  playlistUuid?: string;
  color?: string;
}
const VideoStrip: React.FC<VideoStripProps> = ({
  videoList,
  playlistUuid,
  color,
}) => {

  const vimeoPreviews = useMemo(() => {
    return mapVideosToPreviews(videoList || []);
  }, [videoList]);

  return (
    <Swiper navigation={true} modules={[Navigation]} {...commonSwiperSettings}>
      {videoList.map((v, i) => (
        <SwiperSlide key={i}>
          <InfoCard
            key={i}
            color={color}
            description={v.title}
            url={
              v.external_url ||
              (playlistUuid ?
                `/video/${playlistUuid}/${v.uuid}` : // go to playlist video detail
                `/video/${v.uuid}` // go to video detail
              )
            }
            target={v.external_url ? '_blank' : '_self'}
            cardBackground={v.landscape_thumbnail?.path}
            cardBackgroundPortrait={v.portrait_thumbnail?.path}
            vimeoPreview={vimeoPreviews.get(v.uuid)}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

export default VideoStrip;