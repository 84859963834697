import LoginBox from "../components/LoginBox";
import "./Login.scss";

const Login: React.FC = () => {
  return (
    <div id="page-login">
      <div className="container bg-white">
        <div className="row">
          <div className="col-12 col-md-6 p-0 image-container">
            <img src={`${process.env.PUBLIC_URL}/images/login-banner.png`} />
          </div>

          <div className="col-12 col-md-6 login-container">
            <div>
              <div className="logo-container">
                <img src={`${process.env.PUBLIC_URL}/images/logo.png`} />
              </div>
              <LoginBox />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;