// Import React and React hooks
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { useOutletContext } from "react-router-dom";

// Import  component
import Banner from "../components/Banner";
import ColorCard from "../components/ColorCard";
import VideoStrip from "../components/VideoStrip";

// Import styles and utilities
import "./Home.scss";
import { getSettings, getLatestVideos, getRecommendedVideos, getMostViewedVideos } from "../utils/endpoints";
import { defaultBanner, OutletContextType } from "../utils/shared";
import { Category, Video } from "../utils/models";

const renderVideoStrip = (videoList:Video[], title: string) => {
  return (
    <>
      <div className="title mb-3 text-primary">{title}</div>
      <VideoStrip videoList={videoList} color="orange" />
      <div className="mb-5" />
    </>
  );
};

const Home: React.FC = () => {
  const { t } = useTranslation();
  const [recommended, setRecommended] = useState<Video[]>([]);
  const [latest, setLatest] = useState<Video[]>([]);
  const [mostViewed, setMostViewed] = useState<Video[]>([]);
  const [settings, setSettings] = useState<any>();
  const { channels } = useOutletContext<OutletContextType>();

  const handleFetch = async () => {
    const _recommendedVideos = await getRecommendedVideos();
    const _latestVideos = await getLatestVideos();
    const _mostViewedVideos = await getMostViewedVideos();
    const _settings = await getSettings();

    setSettings(_settings);
    setRecommended(_recommendedVideos);
    setLatest(_latestVideos);
    setMostViewed(_mostViewedVideos);
  }

  useEffect(() => {
    handleFetch();
  }, []);

  return (
    <div id="page-home">
      <Banner img={settings?.home_page_banner || defaultBanner}>
      <div style={{ textAlign: "right" }}>
          <Trans i18nKey="home_banner_caption_top" />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Trans i18nKey="home_banner_caption_bottom" />
      </div>
      </Banner>

      <div className="container">
        <div className="channel-container">
          {channels && channels.map((channel: Category, i: number) => (
            <ColorCard color={channel.color} key={channel.slug} slug={channel.slug} >
              <div className="color-card-content">
                <small>{channel.title}</small>
                <br />
                <b>{channel.subtitle}</b>
              </div>
              <div className="color-card-icon">
                  <img src={`${process.env.PUBLIC_URL}/images/channel-${i % 4 + 1}.png`} alt="color-card-icon" style={{ visibility: 'hidden' }} />
              </div>
            </ColorCard>
          ))}
        </div>
      </div>

      <div className="container pt-5">
        {recommended.length > 0 && renderVideoStrip(recommended, t('recommended'))}
        {latest.length > 0 && renderVideoStrip(latest, t('latest'))}
        {mostViewed.length > 0 && renderVideoStrip(mostViewed, t('most_viewed'))}
      </div>
    </div>
  );
}

export default Home;
