import { AppState, Category, Playlist, ProfilePlaylistMode, Tag, UserProfile } from './models';
import { initialState } from './shared';

const reducer = (
  state = initialState,
  { type, payload }: { type: string; payload?: any }
): AppState => {
  switch (type) {
    case 'SET_USER':
      return {
        ...state,
        currentUser: payload as UserProfile,
      };
    case 'SET_MY_PLAYLIST':
      return {
        ...state,
        myPlaylists: payload as Playlist[],
      };
    case 'SET_VIEWING_PLAYLIST':
      return {
        ...state,
        viewingPlaylist: payload as Playlist,
      };
    case 'SET_PROFILE_PLAYLIST_MODE':
      return {
        ...state,
        profilePlaylistMode: payload as ProfilePlaylistMode,
      };
    case 'SET_CHANNELS':
      return {
        ...state,
        channels: payload as Category[],
      };
    case 'SET_TAGS':
      return {
        ...state,
        tags: payload as Tag[],
      };
    case 'RESET_STATE':
      return initialState;
    default:
      return state;
  }
};

export default reducer;