//react
import { useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
// style
import "./LoginBox.scss";
// utils
import { login } from "../utils/endpoints";
import { getMyProfile } from './../utils/endpoints';
import { setCurrentUser } from './../utils/actions';
import { AppState, UserProfile } from "../utils/models";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import {toast} from "react-toastify";

type LoginBoxProps = {
  currentUser: UserProfile | null;
  onClose?: () => void;
};

const LoginBox: React.FC<LoginBoxProps> = ({ currentUser, onClose }) => {
  const { t } = useTranslation();

  // useState: login and password
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  // On pressing the login button, call login()
  const onLogin = () => {
    if (!email) {
      toast.error(`Please provide your e-mail address.`);
      return;
    } else if (!password) {
      toast.error(`Please provide your password.`);
      return;
    }

    login(email, password).then(response => {
      if (response === undefined) {
        return;
      }
      getProfile(setCurrentUser);
    })
  };

  const getProfile = async (setCurrentUser: any) => {
    const response = await getMyProfile();
    if (response?.user){ // success to get profile = logged in
      setCurrentUser(response?.user);
      const message = response.user.user_profile?.english_name ?
        `Welcome to EdFlix, ${response.user.user_profile?.english_name}.` :
        'Welcome to EdFlix.';
      toast.success(message);

      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  const handleKeyDown = (event: any) => {
    // Allow using Enter key instead of clicking the "login" button
    if (event.keyCode === 13) {
      onLogin();
    }
  };

  return (
    !currentUser ?
      <div className="login-box">
        <div className="login-box-header">
          {
            onClose &&
              <a className="icon close-popup-trigger" onClick={onClose}>
                <FontAwesomeIcon icon={faClose} />
              </a>
          }
        </div>
        <div className="login-box-icon">
          <img src={`${process.env.PUBLIC_URL}/images/user.png`} alt="login-box-icon" />
        </div>
        <div className="login-box-body">
          <div className="login-box-title my-4">
            {t('user_login')}
          </div>
          <div className="input-container mb-3">
            <div className="icon">
              <img src={`${process.env.PUBLIC_URL}/images/icon-user.svg`} alt="user-icon" />
            </div>
            <input
              type="text"
              placeholder={t('email')}
              value={email}
              onKeyDown={handleKeyDown}
              onChange={(ev) => setEmail(ev.target.value)} />
          </div>
          <div className="input-container mb-3">
            <div className="icon">
              <img src={`${process.env.PUBLIC_URL}/images/icon-lock.svg`} alt="lock-icon" />
            </div>
            <input
              type="password"
              placeholder={t('password')}
              value={password}
              onKeyDown={handleKeyDown}
              onChange={(ev) => setPassword(ev.target.value)} />
          </div>

          <button className="btn btn-primary" onClick={onLogin}>
            {t('button.login')}
          </button>
        </div>
      </div>
      :
      <></>
  );
};

const mapStateToProps = (state: AppState) => ({
  currentUser: state.currentUser
});

export default connect(mapStateToProps)(LoginBox);
