import {useSelector} from "react-redux";
import {Navigate, Outlet} from "react-router-dom";
import React from "react";
import {UserProfile} from "./utils/models";

const ProtectedRoute = () => {
    const { currentUser } = useSelector((state: { currentUser: UserProfile }) => state);

    if (!currentUser && !localStorage.getItem('currentUser')) {
        console.debug('Trying to access private route without login. Redirecting to /.');
        return <Navigate to="/" replace/>;
    }
    else {
        return <Outlet/>;
    }
}
export default ProtectedRoute;
