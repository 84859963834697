import {styled, Theme} from "@mui/material/styles";
import {Tooltip, tooltipClasses, TooltipProps} from "@mui/material";

// Copied from App.scss; importing it doesn't work as node-sass doesn't seem to like var names with a hyphen in them
const colorMap: { [name: string]: string } = {
    "purple": "#812f8e",
    "blue": "#32a5dd",
    "green": "#1eaf68",
    "orange": "#fe5900",
};

const BootstrapTooltip = styled(
    ({ className, ...props }: TooltipProps) => <Tooltip {...props} arrow classes={{ popper: className}} />
)(
    ({ color }: {color: string | undefined}) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: "white",
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: "white",
            filter: "drop-shadow(0px 0px 4px rgba(0, 0, 0, .3))",
            padding: "0.8em",
            fontSize: "0.9em",
            fontWeight: 500,
            color: (color && colorMap[color]) || colorMap["orange"],
        },
    })
);

export default BootstrapTooltip;