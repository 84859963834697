import { Category, Playlist, ProfilePlaylistMode, Tag, UserProfile } from "./models";

export const setCurrentUser = (payload: UserProfile) => ({
    type: 'SET_USER',
    payload
})
export const setMyPlaylists = (payload: Playlist[]) => ({
    type: 'SET_MY_PLAYLIST',
    payload
})
export const setViewingPlaylist = (payload: Playlist) => ({
    type: 'SET_VIEWING_PLAYLIST',
    payload
})

export const setProfilePlaylistMode = (payload: ProfilePlaylistMode) => ({
    type: 'SET_PROFILE_PLAYLIST_MODE',
    payload
})
export const setChannels = (payload: Category[]) => ({
    type: 'SET_CHANNELS',
    payload
})
export const setTags = (payload: Tag[]) => ({
    type: 'SET_TAGS',
    payload
})
