import { api } from "./api";
import { Category, Pagination, Playlist, Video } from "./models";

const getHeaders = () => {
    return {
        'X-CSRF-TOKEN': localStorage.getItem('csrfToken'),
    };
}

// API
// Unauthenticated
export const isLoggedIn = async () => {
  const response = await api().post('/is-logged-in');
  return response?.data;
}

export const login = async (email: string, password: string) => {
  const response = await api().post('/login', {
    username: email,
    password: password,
  });
  return response?.data || undefined;
}

export const logout = async () => {
  const response = await api().post(
      '/logout',
      {},
      { headers: getHeaders() }
  );
  return response?.data || undefined;
}

export const changePassword = async (
  data: {
    currentPassword: string, 
    newPassword: string, 
    confirmPassword: string 
  }) => {
  const response = await api().post(
      '/change-password',
      data,
      { headers: getHeaders() }
  );
  return response?.status;
}

export const getSettings = async () => {
  const response = await api().get('/settings');
  return response?.data?.settings || [];
}

export const getSearchVideos = async (filter: {}) => {
    const response = await api().post(`/videos/search`, filter);
    const data: Pagination = response?.data;
    return data || undefined;
}

export const getChannelInfo = async (slug: string, page: number = 1) => {
  const response = await api().get(`/categories/${slug}/${page}`);
  if (!response?.data) {
    return;
  }
  const data = response.data;
  return Promise.resolve({
    level: data.category.level,
    title: data.category.title,
    alternative_title: data.category.alternative_title,
    subtitle: data.category.subtitle,
    description: data.category.description,
    color: data.category.color,
    banner: data.category.banner?.path,
    slug: data.category.slug,
    parent: data.category.parent,
    children: data.category.children,
    videos: data.category.videos,
    pagination: data.category.pagination,
  } as Category);
}

export const getChannels = async () => {
  const { data } = await api().get('/categories/channels');
  return data?.categories || [];
}

export const getTags = async () => {
  const { data } = await api().get('/tags/list');
  return data?.tags || [];
}

export const getRecommendedVideos = async (): Promise<Video[]> => {
  const { data } = await api().get('/videos/recommended');
  return data?.videos || [];
};

export const getRelatedRecommendedVideos = async (video: Video): Promise<Video[]> => {
  const { data }  = await api().get(`/videos/recommended/${video.uuid}`);
  return data?.videos || [];
};

export const getLatestVideos = async (): Promise<Video[]> => {
    const { data } = await api().get(`/videos/latest`);
    return data?.videos || [];
};

export const getMostViewedVideos = async (): Promise<Video[]> => {
    const { data } = await api().get(`/videos/most-viewed`);
    return data?.videos || [];
};

export const getVideo = async (uuid: string): Promise<Video | null> => {
    const { data } = await api().get(`/videos/${uuid}`);
    return data?.video || null;
};

export const markVisitVideoPage = async (videoUuid: string) => {
  const response = await api().post(`/user-interactions/visit`, {
    video_uuid: videoUuid,
  });
  return response?.status;
}

export const markViewVideo = async (videoUuid: string) => {
  const response = await api().post(`/user-interactions/view`, {
    video_uuid: videoUuid,
  });
  return response?.status;
}

// Authenticated 
// need { shouldStay: true } if won't redirect to home page when get auth err
export const getMyProfile = async () => {
  const response = await api().get('/user-profiles');
  localStorage.setItem('currentUser', JSON.stringify(response?.data.user));
  localStorage.setItem('csrfToken', response?.data.csrf_token);
  return response?.data || [];
}

export const getBookmarks = async (): Promise<Video[] | null> => {
  const response = await api().get(`/user-interactions/getBookmarks`);
  return response.data.bookmarked || [];
}

export const getMyPlaylists = async (): Promise<Playlist[] | null> => {
  const response = await api().get(`/playlists/`);
  return response?.data?.playlists || [];
}

export const like = async (videoUuid: string) => {
  const response = await api().post(
      `/user-interactions/like`,
      { video_uuid: videoUuid },
      { headers: getHeaders() }

  );
  return parseInt(response.data) || 0;
}

export const unlike = async (videoUuid: string) => {
  const response = await api().post(
      `/user-interactions/unlike`,
      { video_uuid: videoUuid },
      { headers: getHeaders() }
  );
  return parseInt(response?.data ?? '0') || 0;
}

export const bookmark = async (videoUuid: string) => {
  const response = await api().post(
      `/user-interactions/bookmark`,
      { video_uuid: videoUuid },
      { headers: getHeaders() }
  );
  return response?.status;
}

export const unbookmark = async (videoUuid: string) => {
  const response = await api().post(
      `/user-interactions/unbookmark`,
      { video_uuid: videoUuid },
      { headers: getHeaders() }
  );
  return response?.status;
}

export const getPlaylist = async (uuid: string): Promise<Playlist | null> => {
  const { data } = await api().get(`/playlists/${uuid}`);
  return data?.playlist;
};

export const createPlaylist = async (formValues: any) => {
  const response = await api().post(
      `/playlists/`,
      {
        title: formValues.playlistName,
        description: '',
        playlist_type: 'User',
        share_type: 'Public',
      },
      { headers: getHeaders() }
  );
  return response?.data || undefined;
}

export const updatePlaylist = async (formValues: any) => {
  const response= await api().post(
      `/playlists/${formValues.uuid}`,
      {
        title: formValues.playlistName,
      },
      { headers: getHeaders() }
  );
  return response?.data || undefined;
}

export const removePlaylist = async (uuid: string) => {
  const response = await api().delete(
      `/playlists/${uuid}`,
      { headers: getHeaders() }
  );
  return response?.status;
}

export const addVideoToPlaylist = async (playlistUuid: string, videoUuid: string) => {
    const response = await api().post(
        `/playlists/${playlistUuid}/video/${videoUuid}`,
        {},
        { headers: getHeaders() }
    );
    return response;
}


export const removeVideoFromPlaylist = async (playlistUuid: string, videoUuid: string) => {
  const response = await api().delete(
      `/playlists/${playlistUuid}/video/${videoUuid}`,
        { headers: getHeaders() }
      );
  return response?.status;
}

export const updatePlaylistVideosOrder = async (playlistUuid: string, orderedVideoUuids: string[]) => {
  const response = await api().post(
      `/playlists/reorder/${playlistUuid}`,
      { orderedVideoUuids: orderedVideoUuids },
      { headers: getHeaders() }
  );
  return response;
}

