export interface VideoDetailBtnClickEvent {
  user_uuid?: string;
  class?: string;
  form?: string;
  key_stage?: string;
  video_uuid?: string;
  video_title?: string;
  video_category?: string;
  action?: "Like"|"Bookmark"|"Share";
  value?: number;
}

export interface VideoViewDurationEvent {
  user_uuid?: string;
  class?: string;
  form?: string;
  key_stage?: string;
  video_uuid?: string;
  video_title?: string;
  video_category?: string;
  elapsed_time?: number;
}

export interface ViewEvent {
    user_uuid?: string;
    class?: string;
    form?: string;
    key_stage?: string;
    video_uuid?: string;
    video_title?: string;
    video_category?: string;
    action?: "View";
    value?: number;
}

export function pushVideoDetailBtnClickEvent(
    event: VideoDetailBtnClickEvent
): void {
  window.dataLayer.push({
    event: 'video_detail_btn_click',
    ...event
  });
}

export function pushVideoViewDurationEvent(
    event: VideoViewDurationEvent
): void {
  window.dataLayer.push({
    event: 'video_view_duration',
    ...event
  });
}

export function pushViewEvent(
    event: ViewEvent
): void {
  window.dataLayer.push({
    event: 'view_video',
    ...event
  });
}

