import { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import "./SearchBox.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faSearch, faSliders } from "@fortawesome/free-solid-svg-icons";
import BootstrapTooltip from "./BootstrapTooltip";

const SearchBox: React.FC = () => {
  const { t } = useTranslation();
  const [showInput, setShowInput] = useState<boolean>(false);
  const [isClosing, setIsClosing] = useState<boolean>(false);
  const navigate = useNavigate();
  const [keywords, setKeywords] = useState<string>('');
  const [serializedFilter, setSerializedFilter] = useState('');

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setKeywords(event.target.value)
    let filter = {
      keywords: event.target.value,
      categoryIds: [],
      tagIds: [],
      fromDateStr: '',
      toDateStr: '',
    };
    setSerializedFilter(encodeURIComponent(JSON.stringify(filter)));
  };

  const onClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
      setShowInput(false);
    }, 300);
  }

  const handleSearchClick = () => {
    if (!showInput) {
      setShowInput(true)
    } else {
      navigate(`/search/${serializedFilter}`, {
        state: {
          keywords: keywords || undefined,
          categoryIds: undefined,
          tagIds: undefined,
          fromDate: undefined,
          toDate: undefined,
        }
      })
    }
  }

  const handleKeyDown = (event: any) => {
    // Allow using Enter key to search
    if (event.keyCode === 13 && keywords) {
      handleSearchClick();
    } else if (event.keyCode === 13 && !keywords) {
      event.preventDefault();
    }
  };

  return (
    <div className={`search-box ${showInput ? '' : 'hide'} ${isClosing ? 'closing' : ''}`}>
      <div className="input-container">
        <BootstrapTooltip title="Search" color="orange">
          <div className="icon" onClick={() => handleSearchClick()}>
            <FontAwesomeIcon icon={faSearch} />
          </div>
        </BootstrapTooltip>
        <form>
          <input
            type="search"
            placeholder={t('search_placeholder')}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            autoComplete="off"
          />
        </form>
        <BootstrapTooltip title="Advanced search" color="orange">
          <a className="icon" href="/search">
            <FontAwesomeIcon icon={faSliders} />
          </a>
        </BootstrapTooltip>
        <BootstrapTooltip title="Cancel search" color="orange">
          <a className="icon" onClick={onClose}>
            <FontAwesomeIcon icon={faClose} />
          </a>
        </BootstrapTooltip>
      </div>
    </div>
  );
}

export default SearchBox;