import { useTranslation } from "react-i18next";
import ProfilePicture from "./ProfilePicture";
import { UserProfile } from "../utils/models";
import { FormEvent, useEffect, useState } from "react";
import "./ProfileEdit.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKey } from "@fortawesome/free-solid-svg-icons";
import FormDialog from "./FormDialog";
import { toast } from "react-toastify";
import { changePassword } from "../utils/endpoints";
import { useNavigate } from "react-router-dom";
import { useClearStorage } from "../utils/shared";

const enum Role {
  STUDENT = 'Student',
  STAFF = 'Staff',
}

export interface ProfileEditProps {
  user: UserProfile | null;
}

const ProfileEdit: React.FC<ProfileEditProps> = ({ user }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const clearStorage = useClearStorage();
  const [mode, setMode] = useState('view');
  const [profile, setProfile] = useState(user);
  const [showChangePwPopup, setShowChangePwPopup] = useState<boolean>(false);
  let role = profile?.user_profile.role;

  useEffect(() => {
    if (user) {
      setProfile(user);
    } else {
      const storedUser = localStorage.getItem('currentUser');
      if (storedUser) {
        setProfile(JSON.parse(storedUser));
      }
    }
  }, [])

  const onSubmit = (event: FormEvent) => {
    event.preventDefault(); // avoid page reload
    const { current_password, new_password, confirm_password } = event.target as typeof event.target & {
      current_password: { value: string };
      new_password: { value: string };
      confirm_password: { value: string };
    };
    const fields = [
      { name: 'current_password', label: 'Current password', value: current_password.value },
      { name: 'new_password', label: 'New password', value: new_password.value },
      { name: 'confirm_password', label: 'Confirm password', value: confirm_password.value }
    ];

    for (const field of fields) {
      if (!field.value) {
        toast.error(`${field.label} is required.`);
        return;
      }
    }

    let data = {
      currentPassword: current_password.value,
      newPassword: new_password.value,
      confirmPassword: confirm_password.value,
    }
    changePassword(data)
      .then(responseStatus => {
        if (responseStatus === 200) {
          toast.success("Successfully change password. Please re-login");
          clearStorage(); //  After reset password success, rainLab will auto logout the user. Frontend clear redux to avoid wrong login status
          setTimeout(() => {
            navigate('/')
          }, 1000);
        }
      });
  };

  return (
    <div id="profile-edit">
      <div className="d-flex">
        <div className="title align-self-end">
          {t('my_profile')}
        </div>
        {/* header right btn */}
        {role === Role.STAFF ?
          <div
            className="ms-auto">
            {/* desktop */}
            <button
              className="btn btn-primary d-none d-md-inline-block"
              onClick={() => setShowChangePwPopup(true)}>
              Change Password
            </button>

            {/* mobile */}
            <button
              className="btn btn-link mx-1 p-0 d-md-none"
              onClick={() => setShowChangePwPopup(true)}>
              <FontAwesomeIcon icon={faKey} />
            </button>
          </div>
          :
          <div className="ms-auto invisible">&nbsp;</div>
        }
      </div>
      <hr />
      <div className="info-container">
        <div className={`user-detail-container ${mode}`}>
          <div className="detail">
            <span className="label">{t('english_name')}</span>
            <span className="colon">:</span>
            <span className="value">{profile?.user_profile.english_name}</span>
          </div>
          <div className="detail">
            <span className="label">{t('chinese_name')}</span>
            <span className="colon">:</span>
            <span className="value">{profile?.user_profile.chinese_name}</span>
          </div>
          {role === Role.STUDENT && <div className="detail">
            <span className="label">{t('student_id')}</span>
            <span className="colon">:</span>
            <span className="value">{profile?.user_profile.school_id_number}</span>
          </div>}
          {role === Role.STUDENT && <div className="detail">
            <span className="label">{t('class')}</span>
            <span className="colon">:</span>
            <span className="value">{profile?.user_profile.class}</span>
          </div>}
          {role === Role.STUDENT && <div className="detail">
            <span className="label">{t('class_number')}</span>
            <span className="colon">:</span>
            <span className="value">{profile?.user_profile.class_number}</span>
          </div>}
          <div className="detail">
            <span className="label">{t('email')}</span>
            <span className="colon">:</span>
            <span className="value">{profile?.email}</span>
          </div>
        </div>
      </div>
      {showChangePwPopup &&
        <FormDialog
          title="Change Password"
          placeholder={t('new_playlist_name_placeholder')}
          previousName={'viewingPlaylist?.title'}
          submitLabel='Confirm'
          setShowPopup={setShowChangePwPopup}
          onSubmit={onSubmit}
        >
          <FormDialogContent />
        </FormDialog>
      }
    </div>
  );
}

const FormDialogContent: React.FC = () => {
  const inputFields = [
    { name: "current_password", placeholder: "Current password" },
    { name: "new_password", placeholder: "New password" },
    { name: "confirm_password", placeholder: "Confirm password" }
  ];

  return (
    <div className="d-flex flex-column">
      {inputFields.map((field) => (
        <div className="input-container" key={field.name}>
          <label style={{ width: '200px' }}>{field.placeholder}:</label>
          <input
            type="password"
            name={field.name}
            placeholder={field.placeholder}
          />
        </div>
      ))}
    </div>
  );
};

export default ProfileEdit;