// react
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
// style
import "./ProfileBookmarks.scss";
// component
import VideoCard from "./VideoCard";
// utils
import { Video } from "../utils/models";
import { getBookmarks } from "../utils/endpoints";
import { mapVideosToPreviews } from "../utils/shared";

const ProfileBookmarks: React.FC = () => {
  const { t } = useTranslation();
  const [bookmarks, setBookmarks] = useState<Video[] | null>([]);
  
  const vimeoPreviews = useMemo(() => {
    return mapVideosToPreviews(bookmarks || []);
  }, [bookmarks]);

  const handleFetch = async () => {
    const _bookmarks = await getBookmarks();
    setBookmarks(_bookmarks);
  }

  useEffect(() => {
    handleFetch();
  }, []);

  return (
    <div id="profile-bookmarks">
      <div className="d-flex">
        <div className="title align-self-end">
          {t('my_bookmarks')}
        </div>
        <div className="ms-auto invisible">&nbsp;</div>
      </div>

      <hr />

      {/* #86endtjec temp hide sort by btn */}
      {/* <div className="mb-3">
        <a className="btn-sort text-dark">
          <img src={`${process.env.PUBLIC_URL}/images/icon-sort.svg`} className="me-2" />
          {t('button.sort_by')}
        </a>
      </div> */}

      <div className="video-container">
        { bookmarks?.length === 0 ? 
          <div>{t('bookmark_empty')}</div> :
          bookmarks?.map(v => (
            <VideoCard
              key={v.uuid}
              apiParam={{videoUuid: v.uuid}}
              thumbnail={v?.landscape_thumbnail?.path || undefined}
              thumbnailPortrait={v?.portrait_thumbnail?.path || undefined}
              color="orange"
              title={v.title}
              cardItemType="bookmarkVideo"
              cardClickAction="playVideo"
              playVideoUrl={`/video/${v.uuid}`}
              hashtags={v.tags}
              date={v.created_at}
              handleDataRefresh={handleFetch}
              vimeoPreview={vimeoPreviews.get(v.uuid)}
              allowDelete
            />
        ))}
      </div>
    </div>
  );
}

export default ProfileBookmarks;