// react
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
// component
import Banner from "../components/Banner";
import ProfileEdit from "../components/ProfileEdit";
import ProfilePicture from "../components/ProfilePicture";
import ProfilePlaylists from "../components/ProfilePlaylists";
import ProfileBookmarks from "../components/ProfileBookmarks";
// style
import "./Profile.scss";
// utils
import { AppState, UserProfile } from "../utils/models";

interface Segment {
  name: string,
  icon: string,
  label: string,
  component: React.ReactNode,
}

type ProfileProps = {
  currentUser: UserProfile | null;
};

const Profile: React.FC<ProfileProps> = ({ currentUser }) => {
  const [user, setUser] = useState<UserProfile | null>(null);
  const segments: Segment[] = [
    {
      name: 'edit',
      icon: 'icon-edit',
      label: 'my_user_profile',
      component: <ProfileEdit user={user} />
    },
    {
      name: 'playlists',
      icon: 'icon-add-to-list',
      label: 'my_playlists',
      component: <ProfilePlaylists />
    },
    {
      name: 'bookmarks',
      icon: 'icon-bookmark',
      label: 'my_bookmarks',
      component: <ProfileBookmarks />
    },
  ];

  const { t } = useTranslation();
  const [segment, setSegment] = useState<Segment>(segments[0]);
  useEffect(() => {
    if (currentUser) {
      setUser(currentUser);
    }
    if (!currentUser && localStorage.getItem('currentUser')) {
      const storedUser = localStorage.getItem('currentUser');

      if (typeof storedUser === 'string') {
        setUser(JSON.parse(storedUser));
      }
    }
  }, [])

  return (
    <div id="page-profile">
      <Banner img={`${process.env.PUBLIC_URL}/images/profile-banner.png`} />

      <div className="profile-header">
        <div className="container">
          <ProfilePicture src={currentUser?.avatar?.path}/>
          <div className="info-container ms-3">
            <div className="title">{currentUser?.user_profile?.english_name}</div>
            <div className="subtitle">{currentUser?.user_profile?.role}</div>
            <div>{currentUser?.email}</div>
          </div>
        </div>

        <hr />

        <div className="container segment-container">
          {segments.map((v, i) => (
            <button
              key={i}
              className={`segment-button ${segment.name === v.name ? 'active' : ''}`}
              onClick={() => setSegment(v)}>
              <img src={`${process.env.PUBLIC_URL}/images/${v.icon}${segment.name === v.name ? '-orange' : ''}.svg`} alt="tab-icon"/>
              <span>{t(v.label)}</span>
            </button>
          ))}
        </div>
      </div>

      <div className="profile-body">
        <div className="container">
          <div className="segment-body">
            {segment.component}
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: AppState) => ({
  currentUser: state.currentUser
});

export default connect(mapStateToProps)(Profile);