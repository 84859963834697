// React
import React, {FormEvent, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {connect} from "react-redux";
import {isMobile} from 'react-device-detect';
import {InlineShareButtons} from 'sharethis-reactjs';
import { Helmet } from 'react-helmet';

// component
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormDialog from "../components/FormDialog";
import Star from '../components/Star';
import VideoCard from '../components/VideoCard';
import VideoStrip from '../components/VideoStrip';
import Vimeo, {TimeUpdateEvent} from "@u-wave/react-vimeo";

// style and icon
import './VideoDetail.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlay } from '@fortawesome/free-solid-svg-icons';
import { formatDate } from '../utils/shared';

// utils and interface
import {
    getVideo,
    bookmark,
    unbookmark,
    like,
    unlike,
    markVisitVideoPage,
    markViewVideo,
    getMyPlaylists,
    createPlaylist,
    getPlaylist,
    getRelatedRecommendedVideos
} from '../utils/endpoints';
import {AlertContent, AppState, Playlist, Tag, UserProfile, Video} from "../utils/models";
import {pushVideoDetailBtnClickEvent, pushVideoViewDurationEvent, pushViewEvent} from "../utils/dataLayerEvents";
import {useDebounce} from "../hooks";
import {toast} from "react-toastify";
import BootstrapTooltip from "../components/BootstrapTooltip";

type VideoDetailProps = {
    currentUser: UserProfile | null;
};

const VideoDetail: React.FC<VideoDetailProps> = ({currentUser}) => {
    const {t} = useTranslation();
    const {videoUuid, playlistUuid} = useParams();
    const navigate = useNavigate();
    const [seoTags, setSeoTags] = useState<string>('');

    const [isBookmarked, setIsBookmarked] = useState<Boolean | null>(null);
    const [wasBookmarked, setWasBookmarked] = useState<Boolean | null>(null);
    const [isLiked, setIsLiked] = useState<Boolean | null>(null);
    const [wasLiked, setWasLiked] = useState<Boolean | null>(null);
    const [likeCount, setLikeCount] = useState<number>(0);
    const [isVisitedVideoPage, setIsVisitedVideoPage] = useState(false); // New state variable
    const [videoIsViewed, setVideoIsViewed] = useState(false);
    const [openAddToPlaylist, setOpenAddToPlaylist] = useState(false);
    const [openShare, setOpenShare] = useState(false);
    const [myPlaylists, setMyPlaylists] = useState<Playlist[] | null>([]);
    const [playlist, setPlaylist] = useState<Playlist | null>(null);
    const [recommended, setRecommended] = useState<Video[]>([]);
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [videoList, setVideoList] = useState<Video[] | undefined>([]);
    const [video, setVideo] = useState<Video | null>();
    const [viewCount, setViewCount] = useState<number>(0);
    const [nextCallTime, setNextCallTime] = useState(60);
    const KEY_STAGE_P1_P3 = 'P1-P3';
    const KEY_STAGE_P4_P6 = 'P4-P6';
    const KEY_STAGE_S1_S3 = 'S1-S3';
    const KEY_STAGE_S4_S6 = 'S4-S6';
    const KEY_STAGE_STAFF = 'Staff';
    const KEY_STAGE_PUBLIC = 'Public';

    let durationMinutes = 0;
    try {
        if (video) {
            durationMinutes = Math.ceil((parseInt(video.whitelisted_vimeo_metadata['duration']) || 0) / 60);
        }
    } catch (e) {
        console.error(`Failed to parse duration: ${e}.`);
    }

    const handleMyPlaylistFetch = async () => {
        setMyPlaylists(await getMyPlaylists());
    }

    const markVisit = async () => {
        if (video) {
            await markVisitVideoPage(video.uuid);
            setIsVisitedVideoPage(true);
        }
    }

    const handleRecommendFetch = async (video: Video) => {
        const _recommendedVideos = await getRelatedRecommendedVideos(video);
        setRecommended(_recommendedVideos);
    }

    function deriveClass(classValue: string | undefined, roleValue?: string | undefined) {
        if (!roleValue) {
            return KEY_STAGE_PUBLIC;
        }
        if (!classValue) {
            return KEY_STAGE_STAFF;
        }
        return classValue;
    }

    function deriveForm(classValue: string | undefined, roleValue?: string | undefined) {
        if (!roleValue) {
            return KEY_STAGE_PUBLIC;
        }
        if (!classValue) {
            return KEY_STAGE_STAFF;
        }
        return classValue?.substring(0, 2);
    }

    function deriveKeyStage(classValue: string | undefined, roleValue?: string | undefined) {
        if (!roleValue) {
            return KEY_STAGE_PUBLIC;
        }
        if (!classValue) {
            return KEY_STAGE_STAFF;
        }
        const level = classValue?.charAt(0);
        let year: number | string = '';
        try {
            year = parseInt(classValue?.substring(1), 10);
        } catch (error) {
            console.error(`Failed to parse year: ${error}.`);
        }

        let keyStage;
        if (level === 'P') {
            keyStage = year <= 3 ? KEY_STAGE_P1_P3 : KEY_STAGE_P4_P6;
        } else if (level === 'S') {
            keyStage = year <= 3 ? KEY_STAGE_S1_S3 : KEY_STAGE_S4_S6;
        }
        return keyStage;
    }

    useEffect(() => {
        if (!video) {
            return;
        }

        setSeoTags(video?.tags?.map((el:Tag) => {return el?.name}).join(', ') || '');

        if (!isVisitedVideoPage) {
            markVisit();
            handleRecommendFetch(video);
        }
        setLikeCount(video?.like_count || 0);
        setViewCount(video?.view_count || 0);
    }, [video]);

    useEffect(() => {
        handleVideoFetch();
        handleVideoListFetch();
    }, []);

    useEffect(() => {
        if (currentUser) {
            handleMyPlaylistFetch();
        }
    }, [currentUser]);

    // Debounce like/dislike API requests
    useDebounce(
        () => {
            // Sanity checks
            if (
                // Video info not yet retrieved
                !video?.uuid ||
                // This is the initial like/dislike state
                wasLiked === null ||
                // Like/dislike state has not changed
                wasLiked === isLiked
            ) {
                return;
            }

            const videoUuid = video.uuid;

            if (isLiked) {
                like(videoUuid).then((likeCount) => {
                    setWasLiked(isLiked)
                    setLikeCount(likeCount);
                    toast.success('Thanks for liking the video!');
                    pushVideoDetailBtnClickEvent({
                        user_uuid: currentUser?.uuid,
                        class: deriveClass(currentUser?.user_profile?.class, currentUser?.user_profile?.role),
                        form: deriveForm(currentUser?.user_profile?.class, currentUser?.user_profile?.role),
                        key_stage: deriveKeyStage(currentUser?.user_profile?.class, currentUser?.user_profile?.role),
                        video_uuid: video?.uuid,
                        video_title: video?.title,
                        video_category: video?.category?.title,
                        action: 'Like',
                        value: 1,
                    });
                });
            } else {
                unlike(videoUuid).then((likeCount) => {
                    setWasLiked(isLiked)
                    setLikeCount(likeCount)
                    pushVideoDetailBtnClickEvent({
                        user_uuid: currentUser?.uuid,
                        class: deriveClass(currentUser?.user_profile?.class, currentUser?.user_profile?.role),
                        form: deriveForm(currentUser?.user_profile?.class, currentUser?.user_profile?.role),
                        key_stage: deriveKeyStage(currentUser?.user_profile?.class, currentUser?.user_profile?.role),
                        video_uuid: video?.uuid,
                        video_title: video?.title,
                        video_category: video?.category?.title,
                        action: 'Like',
                        value: -1,
                    });
                });
            }
        },
        isLiked
    );

    const handleLikeClick = async () => {
        if (!currentUser || !video) {
            return;
        }
        setIsLiked(!isLiked);
    };

    // Debounce bookmark/unbookmark API requests
    useDebounce(
        () => {
            // Sanity checks
            if (
                // Video info not yet retrieved
                !video?.uuid ||
                // This is the initial bookmark state
                wasBookmarked === null ||
                // Bookmark state has not changed
                wasBookmarked === isBookmarked
            ) {
                return;
            }

            const videoUuid = video.uuid;

            if (isBookmarked) {
                bookmark(videoUuid).then(() => {
                    pushVideoDetailBtnClickEvent({
                        user_uuid: currentUser?.uuid,
                        class: deriveClass(currentUser?.user_profile?.class, currentUser?.user_profile?.role),
                        form: deriveForm(currentUser?.user_profile?.class, currentUser?.user_profile?.role),
                        key_stage: deriveKeyStage(currentUser?.user_profile?.class, currentUser?.user_profile?.role),
                        video_uuid: video?.uuid,
                        video_title: video?.title,
                        video_category: video?.category?.title,
                        action: 'Bookmark',
                        value: 1,
                    });
                    setWasBookmarked(isBookmarked)
                    toast.success('A bookmark has been added.');
                });
            } else {
                unbookmark(videoUuid).then(() => {
                    setWasBookmarked(isBookmarked)
                    toast.success('The bookmark has been removed.');
                    pushVideoDetailBtnClickEvent({
                        user_uuid: currentUser?.uuid,
                        class: deriveClass(currentUser?.user_profile?.class, currentUser?.user_profile?.role),
                        form: deriveForm(currentUser?.user_profile?.class, currentUser?.user_profile?.role),
                        key_stage: deriveKeyStage(currentUser?.user_profile?.class, currentUser?.user_profile?.role),
                        video_uuid: video?.uuid,
                        video_title: video?.title,
                        video_category: video?.category?.title,
                        action: 'Bookmark',
                        value: -1,
                    });
                });
            }
        },
        isBookmarked
    );

    const handleBookmarkClick = async () => {
        if (!currentUser || !video) {
            return;
        }
        setIsBookmarked(!isBookmarked);
    };

    const handleVideoFetch = async () => {
        if (!videoUuid) {
            return;
        }

        const _video = await getVideo(videoUuid);
        setVideo(_video);
        setIsBookmarked(_video?.is_bookmarked || false);
        setWasBookmarked(_video?.is_bookmarked || false);
        setIsLiked(_video?.is_liked || false);
        setWasLiked(_video?.is_liked || false);
    };

    const handleVideoListFetch = async () => {
        if (!playlistUuid) {
            return;
        }

        const _playlist = await getPlaylist(playlistUuid);
        setPlaylist(_playlist);
        setVideoList(_playlist?.videos);
    };

    const handleOnPlaying = async () => {
        if (!video?.uuid) {
            return;
        }
        if (!videoIsViewed) {
            setVideoIsViewed(true);
            await markViewVideo(video.uuid);

            pushViewEvent(
                {
                    user_uuid: currentUser?.uuid,
                    class: deriveClass(currentUser?.user_profile?.class, currentUser?.user_profile?.role),
                    form: deriveForm(currentUser?.user_profile?.class, currentUser?.user_profile?.role),
                    key_stage: deriveKeyStage(currentUser?.user_profile?.class, currentUser?.user_profile?.role),
                    video_uuid: video?.uuid,
                    video_title: video?.title,
                    video_category: video?.category?.title,
                    action: "View",
                    value: 1,
                }
            )
        }
    };

    const handleOnEnd = async () => {
        if (!playlist?.videos) {
            return;
        }

        const currentVideoIndex: number = playlist.videos.findIndex(video => video.uuid === videoUuid);
        const isLastVideo: boolean = currentVideoIndex + 1 === playlist.videos.length;

        if (isLastVideo) {
            return;
        } else {
            // When the current video finishes playing, go to the next video in the playlist.
            // Can't use navigate() because it doesn't trigger page refresh. The `reloadDocument` attribute isn't supported
            // for some reason.
            window.location.href = `/video/${playlistUuid}/${playlist.videos[currentVideoIndex + 1].uuid}`;
        }
    };

    const handleOnTimeUpdate = (event: TimeUpdateEvent) => {
        const currentTime = event.seconds;

        // Send event every 60 seconds
        if (currentTime >= nextCallTime) {
            pushVideoViewDurationEvent({
                user_uuid: currentUser?.uuid,
                class: deriveClass(currentUser?.user_profile?.class, currentUser?.user_profile?.role),
                form: deriveForm(currentUser?.user_profile?.class, currentUser?.user_profile?.role),
                key_stage: deriveKeyStage(currentUser?.user_profile?.class, currentUser?.user_profile?.role),
                video_uuid: video?.uuid,
                video_title: video?.title,
                video_category: video?.category?.title,
                elapsed_time: 60,
            });

            setNextCallTime(currentTime + 60);
        }
    };

    const handleShareClick = async () => {

        // Push to data layer
        pushVideoDetailBtnClickEvent({
            user_uuid: currentUser?.uuid,
            class: deriveClass(currentUser?.user_profile?.class, currentUser?.user_profile?.role),
            form: deriveForm(currentUser?.user_profile?.class, currentUser?.user_profile?.role),
            key_stage: deriveKeyStage(currentUser?.user_profile?.class, currentUser?.user_profile?.role),
            video_uuid: video?.uuid,
            video_title: video?.title,
            video_category: video?.category?.title,
            action: 'Share',
            value: 1,
        });

        setOpenShare(true);
    };

    const handleClose = () => {
        setOpenShare(false);
        setOpenAddToPlaylist(false);
    };

    const handleAddToListClick = async () => {
        if (!currentUser) {
            return;
        }

        setOpenAddToPlaylist(true);
    };

    const handleCreateNewPlaylistClick = async () => {
        setShowPopup(true)
        setOpenAddToPlaylist(false);
    };

    const handleTagClick = async (tag: Tag) => {
        const tags = [tag.id];
        let filter = {
            keywords: '',
            categoryIds: [],
            tagIds: tags,
            fromDateStr: '',
            toDateStr: '',
        };
        let serializedFilter = encodeURIComponent(JSON.stringify(filter));

        navigate(`/search/${serializedFilter}`, {
            state: {
                keywords: undefined,
                categoryIds: undefined,
                tagIds: tags.length > 0 ? tags : undefined,
                fromDate: undefined,
                toDate: undefined,
            }
        })
    }

    const onCreate = (event: FormEvent) => {
        event.preventDefault(); // avoid page reload
        const playlistName = (event.target as HTMLFormElement).playlist_name.value;

        if (!playlistName) { // missing input
            toast.error('Name is required.');
            return;
        } else {
            createPlaylist({playlistName: playlistName})
                .then(response => {
                    setShowPopup(false);
                    handleMyPlaylistFetch();
                    setOpenAddToPlaylist(true);
                })
        }
    }

    const interactionButtons = [
        {
            className: 'view-counter', // 'marked' to show orange color
            icon: faCirclePlay,
            svgSrc: null,
            alt: 'view-counter',
            label: viewCount > 0 ? viewCount : null,
            disabled: true,
            visible: currentUser || viewCount > 0,
            hint: 'Number of views',
        },
        {
            className: isLiked ? 'like-button marked' : 'like-button', // 'marked' to show orange color
            onClick: handleLikeClick,
            icon: null,
            svgSrc: `${process.env.PUBLIC_URL}/images/icon-like.svg`,
            alt: 'like-button',
            label: likeCount > 0 ? likeCount : null,
            disabled: !currentUser,
            visible: currentUser || likeCount > 0,
            hint: 'Like this video',
        },
        {
            className: isBookmarked ? 'bookmark-button marked' : 'bookmark-button', // 'marked' to show orange color
            onClick: handleBookmarkClick,
            icon: null,
            svgSrc: `${process.env.PUBLIC_URL}/images/icon-bookmark.svg`,
            alt: 'bookmark-button',
            disabled: !currentUser,
            visible: currentUser,
            hint: 'Bookmark this video',
        },
        {
            className: 'share-button',
            onClick: handleShareClick,
            icon: null,
            svgSrc: `${process.env.PUBLIC_URL}/images/icon-share.svg`,
            alt: 'share-button',
            disabled: false,
            visible: true,
            hint: 'Share this video',
        },
        {
            className: 'add-to-list-button',
            onClick: handleAddToListClick,
            icon: null,
            svgSrc: `${process.env.PUBLIC_URL}/images/icon-add-to-list.svg`,
            alt: 'add-to-list-button',
            disabled: !currentUser,
            visible: currentUser,
            hint: 'Add this video to a playlist',
        },
    ];

    return video ? (
        <div id="video-detail" color={video.category.color}>
            <Helmet>
                <title>{video.title} | EdFlix</title>
                <meta name="description" content={`${video?.title} ${video?.description}`} />
                <meta property="og:title" content={video?.title} />
                <meta property="og:video:tag" content={seoTags} />
            </Helmet>
            <div className="bg">
                <div className="overlay"/>
            </div>

            <div className="container">
                <nav aria-label="breadcrumb mt-5">
                    <ol className="breadcrumb text-white">
                        <li className='breadcrumb-item'>
                            <a href="/">Home</a>
                        </li>
                        <li className='breadcrumb-item'>
                            <a href={
                                video.category.parent?.parent?.slug ?
                                    `/channel/${video.category.parent?.parent?.slug}` :
                                    '#'
                            }>
                                {video.category.parent?.parent?.title}
                            </a>
                        </li>
                        <li className='breadcrumb-item'>
                            <a href={
                                video.category.parent?.parent?.slug ?
                                    `/channel/${video.category.parent?.parent?.slug}` :
                                    '#'
                            }>
                                {video.category.parent?.title}
                            </a>
                        </li>
                        <li className='breadcrumb-item'>
                            <a href={`/channel/${video.category.parent?.parent?.slug}/${video.category.slug}`}>
                                {video.category.title}
                            </a>
                        </li>
                    </ol>
                </nav>

                <div className="video-container">
                    <div className="video-container-ratio">
                        <div className="video-container-wrapper">
                            {
                                video.whitelisted_vimeo_metadata.player_embed_url &&
                                <Vimeo
                                    video={video.whitelisted_vimeo_metadata.player_embed_url}
                                    autoplay={true}
                                    controls={true}
                                    onPlaying={handleOnPlaying}
                                    onEnd={handleOnEnd}
                                    onTimeUpdate={handleOnTimeUpdate}
                                    quality={"auto"}
                                />
                            }
                        </div>
                    </div>
                </div>

                <div className="content-container my-3 my-md-5">
                    <div className={`title mb-3`}>
                        <div style={{display: 'block'}}>
                            {video.title.split('\n').map((item, i) => (
                                <div key={i} className="mb-1">
                                    {item}
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="mb-3">
                        <small>
                            Publish
                            time: {formatDate(video.publish_time || video.whitelisted_vimeo_metadata.release_time || video.created_at)}
                            {durationMinutes > 0 && <>
                                <span className='mx-1'>•</span>
                                {durationMinutes} minutes
                            </>}
                        </small>
                    </div>

                    {video.description}
                    <div className='tags-container'>
                        {video.tags?.map((tag, i) => (
                            <div key={i} className="me-3 clickable hashtag" onClick={() => handleTagClick(tag)}>
                                #{tag.name}
                            </div>
                        ))}
                    </div>
                </div>

                <hr/>

                <div className="button-container text-end">
                    {interactionButtons.map((button, index) => (
                        button.visible &&
                        <BootstrapTooltip key={index} title={button.hint} color={video.category.color} placement="top">
                            <div key={index} className={!button.disabled ? 'icon button clickable' : 'icon'}
                                 onClick={button.onClick}>
                                <div className="image">
                                    {button.icon && <FontAwesomeIcon icon={button.icon}/>}
                                    {button.svgSrc &&
                                        <img className={button.className} src={button.svgSrc} alt={button.alt}/>}
                                </div>
                                { button.label && <label>{button.label}</label> }
                            </div>
                        </BootstrapTooltip>
                    ))}
                </div>
                <hr/>
            </div>

            {playlistUuid && videoList ?
                <div className="container">
                    <div className="title my-3 mt-md-5">
                        {t('Playlist')}: {playlist?.title}
                    </div>
                    {/* playlist strip */}
                    <VideoStrip videoList={videoList} playlistUuid={playlistUuid} color={video.category.color}/>
                </div>
                : (
                    recommended && recommended.length > 0 &&
                    <div className="container">
                        <div className="title mb-3 use-color-attrib" color={video.category.color}>{t('recommended')}</div>
                        {/* recommended strip */}
                        <VideoStrip videoList={recommended} color={video.category.color}/>
                    </div>
                )
            }

            {/* Share dialog */}
            <Dialog
                open={openShare}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle sx={{m: 0, p: 4}} id="alert-dialog-title">
                    {"Share to"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" style={{overflowY: "visible"}}>
                        <div className="share-buttons">
                            <InlineShareButtons
                                config={{
                                    alignment: 'center',  // alignment of buttons (left, center, right)
                                    color: 'social',      // set the color of buttons (social, white)
                                    enabled: true,        // show/hide buttons (true, false)
                                    font_size: 16,        // font size for the buttons
                                    labels: null,        // button labels (cta, counts, null)
                                    language: 'en',       // which language to use (see LANGUAGES)
                                    networks: [           // which networks to include (see SHARING NETWORKS)
                                        'whatsapp',
                                        "email",
                                        'facebook',
                                        "twitter",
                                        "wechat",
                                    ],
                                    padding: 20,          // padding within buttons (INTEGER)
                                    radius: 4,            // the corner radius on each button (INTEGER)
                                    show_total: false,
                                    size: 70,             // the size of each button (INTEGER)

                                    // OPTIONAL PARAMETERS

                                    url: window.location.href, // (defaults to current url)
                                    // image: 'https://bit.ly/2CMhCMC',  // (defaults to og:image or twitter:image)
                                    // description: 'custom text',       // (defaults to og:description or twitter:description)
                                    // title: 'custom title',            // (defaults to og:title or twitter:title)
                                    // message: 'custom email text',     // (only for email sharing)
                                    // subject: 'custom email subject',  // (only for email sharing)
                                    // username: 'custom twitter handle' // (only for twitter sharing)
                                }}
                            />
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{m: 2}}>
                    <Button onClick={handleClose}>Cancel</Button>
                </DialogActions>
            </Dialog>

            {/* Add to playlist dialog */}
            <Dialog
                open={openAddToPlaylist}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth={'lg'}
            >
                <DialogTitle sx={{m: 0, p: 4}} id="alert-dialog-title">
                    {"Add to playlist"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <DialogContent dividers>
                            <div className="playlist-container"
                                 style={{
                                     display: 'flex',
                                     gap: 'calc((100% - 90%)/2)',
                                     margin: isMobile ? '0' : '0 30px',
                                     flexDirection: 'row',
                                     flexWrap: isMobile ? 'nowrap' : 'wrap',
                                 }}>
                                {myPlaylists && myPlaylists.length > 0 ?
                                    myPlaylists?.map((playlist, i) => (
                                        <VideoCard style={isMobile ? {minWidth: '85%'} : {width: '30%'}}
                                                   key={playlist.uuid}
                                                   apiParam={{
                                                       playlistUuid: playlist.uuid,
                                                       videoUuid: video.uuid
                                                   }}
                                                   thumbnail={playlist.cover_video?.landscape_thumbnail?.path}
                                                   thumbnailPortrait={playlist.cover_video?.portrait_thumbnail?.path}
                                                   title={playlist.title}
                                                   cardItemType="playlist"
                                                   cardClickAction='addToPlaylist'
                                                   disableAction={(playlist.video_uuids || []).includes(video.uuid)}
                                                   videoCount={playlist.video_uuids?.length || 0}
                                                   setOpenAddToPlaylist={setOpenAddToPlaylist}
                                                   handleDataRefresh={handleMyPlaylistFetch}
                                        />
                                    ))
                                    :
                                    <div>{t('playlist_empty')}</div>
                                }
                            </div>
                        </DialogContent>
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{m: 2}}>
                    <Button onClick={handleCreateNewPlaylistClick}>{t('button.create_new_playlist')}</Button>
                    <Button onClick={handleClose}>Cancel</Button>
                </DialogActions>
            </Dialog>

            {/* Create Playlist dialog */}
            {showPopup &&
                <FormDialog
                    title={t('new_playlist_name')}
                    placeholder={t('new_playlist_name_placeholder')}
                    submitLabel={t('button.create')}
                    setShowPopup={setShowPopup}
                    onSubmit={onCreate}
                />
            }
        </div>
    ) : <></>;
}

const mapStateToProps = (state: AppState) => ({
    currentUser: state.currentUser
});

export default connect(mapStateToProps)(VideoDetail);
