// React
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import TagManager from "react-gtm-module";

// style and utils
import './App.scss';
import { register } from 'swiper/element/bundle';
import {
  getChannels,
  getMyProfile,
  getTags,
  isLoggedIn
} from './utils/endpoints';
import {
  setChannels,
  setCurrentUser,
  setMyPlaylists,
  setTags
} from './utils/actions';
import { AppState, Category, Playlist, Tag, UserProfile } from './utils/models';
// page
import Home from './pages/Home';
import Layout from './components/Layout';
import Channel from './pages/Channel';
import VideoDetail from './pages/VideoDetail';
import Folder from './pages/Folder';
import Login from './pages/Login';
import Profile from './pages/Profile';
import Search from './pages/Search';
import NotFound from './pages/NotFound';
import SearchResult from './pages/SearchResult';
import ProtectedRoute from "./ProtectedRoute";
import { handleMyPlaylistFetch } from './utils/shared';

register();

const handleProfileFetch = async (setCurrentUser: any) => {
  const userHasLoggedIn = await isLoggedIn();
  if (userHasLoggedIn) {
    const response = await getMyProfile();
    setCurrentUser(response?.user);
  }
};

const handleChannelsFetch = async (setChannels: any) => {
  setChannels(await getChannels());
}
const handleTagsFetch = async (setTags: any) => {
  setTags(await getTags());
}

function App({ 
  // redux
  setCurrentUser,
  setMyPlaylists,
  setChannels,
  setTags,
}: { 
  setCurrentUser: (value: UserProfile) => void,
  setMyPlaylists: (value: Playlist[]) => void,
  setChannels: (value: Category[]) => void ,
  setTags: (value: Tag[]) => void 
}) {

  TagManager.initialize({
    gtmId: 'GTM-PGXGQ935'
  });

  useEffect(() => {
    handleProfileFetch(setCurrentUser);
    handleMyPlaylistFetch(setMyPlaylists);
    handleChannelsFetch(setChannels);
    handleTagsFetch(setTags);
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/login' element={<Login />}></Route>
        <Route element={<Layout />}>
          <Route path='/' element={<Home />}></Route>
          <Route path='/channel/:slug' element={<Channel />}></Route>
          <Route path='/channel/:channelSlug/:folderSlug' element={<Folder />}></Route>
          <Route path='/video/:videoUuid' element={<VideoDetail />}></Route>
          <Route path='/video/:playlistUuid/:videoUuid' element={<VideoDetail />}></Route>
          <Route path="/profile" element={<ProtectedRoute/>}>
            <Route path="/profile" element={<Profile/>} />
          </Route>
          <Route path="/search" element={<Search />} />
          <Route path="/search/:serializedFilter" element={<SearchResult />} />
          <Route path='*' element={<NotFound />} ></Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const mapStateToProps = (state: AppState) => ({
  currentUser: state.currentUser,
});

const mapDispatchToProps = (dispatch: any) => ({
  setCurrentUser: (value: UserProfile) => dispatch(setCurrentUser(value)),
  setMyPlaylists: (value: Playlist[]) => dispatch(setMyPlaylists(value)),
  setChannels: (value: Category[]) => dispatch(setChannels(value)),
  setTags: (value: Tag[]) => dispatch(setTags(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);