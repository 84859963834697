// react
import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// style and icon
import "./VideoCard.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightArrowLeft, faCheck, faGears, faPlay } from "@fortawesome/free-solid-svg-icons";
// component
import Star from "./Star";
import VideoCardButton from "./VideoCardButton";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

// interface and utils
import { formatNumber } from "../utils/shared";
import { addVideoToPlaylist, getPlaylist, removePlaylist, removeVideoFromPlaylist, unbookmark } from "../utils/endpoints";
import { AppState, Playlist, ProfilePlaylistMode, Tag } from "../utils/models";
import { connect } from "react-redux";
import { setProfilePlaylistMode, setViewingPlaylist } from "../utils/actions";
import { isMobile } from "react-device-detect";

export interface VideoCardProps {
  style?: any,
  apiParam?: any;
  color?: string;
  thumbnail?: string;
  thumbnailPortrait?: string;
  title?: string;
  hashtags?: Tag[];
  date?: string;
  cardItemType: 'bookmarkVideo' | 'playlist' | 'playlistVideo';
  cardClickAction?: 'playVideo' | 'addToPlaylist' | 'playAll';
  disableAction?: boolean;
  allowShare?: boolean;
  allowDelete?: boolean;
  allowManage?: boolean;
  videoCount?: number; // Useful when we want the video count but don't need all the videos' details
  vimeoPreview?: any,
  handleDataRefresh?: () => Promise<void>; // cardItemType === 'bookmarkVideo' || cardItemType === 'playlist'
  setOpenAddToPlaylist?: Dispatch<SetStateAction<boolean>>;
  playVideoUrl?: string;
  setViewingPlaylist: (value: Playlist) => void,
  setProfilePlaylistMode: (value: ProfilePlaylistMode) => void,
}
const VideoCard: React.FC<VideoCardProps> = ({
  style, // only use when video card in dialog because scss can not apply to element inside dialog
  apiParam,
  color = 'orange',
  thumbnail,
  thumbnailPortrait,
  title,
  hashtags = [],
  cardItemType,
  cardClickAction,
  videoCount,
  disableAction = false,
  allowShare = false,
  allowDelete = false,
  allowManage = false,
  vimeoPreview,
  handleDataRefresh, // reload bookmark videos or reload playlists after rename
  setOpenAddToPlaylist,
  playVideoUrl,
  setViewingPlaylist,
  setProfilePlaylistMode,
}) => {
  const { t } = useTranslation();
  const [isHovering, setIsHovering] = useState(false);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const numOfVideos = videoCount || 0;
  const navigate = useNavigate();

  const handleCardClick = () => {
    if (disableAction) {
      return;
    } else if (cardClickAction === 'playVideo') {
      if (playVideoUrl) {
        window.location.href = playVideoUrl;
      }
    } else if (cardClickAction === 'addToPlaylist') {
      handleAddToPlaylist();
    } else if (cardClickAction === "playAll") {
      handlePlayAll();
    }
  };

  const handlePlayAll = () => {
    const link = apiParam.playlistUuid ?
      `/video/${apiParam.playlistUuid}/${apiParam.videoUuid}` :
      `/video/${apiParam.videoUuid}`
    navigate(link);
  };

  const handleAddToPlaylist = async () => {
    if (disableAction) {
      return;
    }
    const response = await addVideoToPlaylist(apiParam.playlistUuid, apiParam.videoUuid);
    if (response.status === 200) {
      toast.success('The video has been added to the playlist.');
      if (setOpenAddToPlaylist) {
        setTimeout(() => {
          setOpenAddToPlaylist(false);
        }, 500);
      }
      if (handleDataRefresh) {
        handleDataRefresh();
      }
    }
  };

  const loadPlaylist = async () => {
    let playlistUuid = apiParam?.playlistUuid;
    if (!playlistUuid) {
      return;
    }
    const _playlist = await getPlaylist(playlistUuid);
    if (_playlist) {
      setViewingPlaylist(_playlist);
      setProfilePlaylistMode('manage');
    }
  };

  const handleDeleteClick = async () => {
    setOpenConfirmDelete(true)
  }

  const confirmDelete = async () => {
    // check if the video already in bookmarks
    if (!apiParam) {
      return;
    }

    let successMessage = '';
    let responseStatus;
    if (cardItemType === 'playlistVideo') {
      responseStatus = await removeVideoFromPlaylist(apiParam.playlistUuid, apiParam.videoUuid);
      successMessage = 'The video has been removed from the playlist.';
    } else if (cardItemType === 'bookmarkVideo') {
      responseStatus = await unbookmark(apiParam.videoUuid);
      successMessage = 'The bookmark has been removed.';
    } else if (cardItemType === 'playlist') {
      responseStatus = await removePlaylist(apiParam.playlistUuid);
      successMessage = 'The playlist has been removed.';
    }

    if (responseStatus === 200) {
      if (successMessage) {
        toast.success(successMessage);
        setOpenConfirmDelete(false);
      }
      if (handleDataRefresh) {
        await handleDataRefresh();
      } else {
        loadPlaylist(); // refetch viewing playlist videos
      }
    }
  };

  const handleClose = () => {
    setOpenConfirmDelete(false);
  };

  return (
    <>
      <div className="video-card" style={style}
        onMouseOver={() => setIsHovering(true)}
        onMouseOut={() => setIsHovering(false)}>
        <div className={((disableAction || (numOfVideos === 0 && cardClickAction === "playAll")) ? "" : "clickable") + " video-card-thumbnail-container"}>
          <div className="video-card-thumbnail-wrapper">
            {!!thumbnail && (
              <img
                src={thumbnail}
                className="video-card-thumbnail"
                alt="video-card-thumbnail" />
            )}
            {(!!thumbnailPortrait || !!thumbnail) && (
              <img
                src={thumbnailPortrait || thumbnail}
                className="video-card-thumbnail-portrait"
                alt="video-card-thumbnail" />
            )}
            <div
              className={"video-card-overlay"}
              onClick={
                disableAction || (numOfVideos === 0 && cardClickAction === "playAll") ?
                  (() => { }) :
                  handleCardClick}
            >

              {numOfVideos > 0 && cardClickAction === "playAll" &&
                <span>
                  <FontAwesomeIcon icon={faPlay} className="me-2" />
                  {t('button.play_all')}
                </span>
              }

              {cardClickAction === 'addToPlaylist' && disableAction && /* Indicate if the video is already in the playlist */ (
                <span>
                  <FontAwesomeIcon icon={faCheck} className="me-2" />
                  {t('Added')}
                </span>
              )}

            </div>

            {
              isHovering && vimeoPreview && (
                playVideoUrl ?
                  <Link className="link-to-video" to={playVideoUrl} reloadDocument={true}>
                    {vimeoPreview}
                  </Link> :
                  vimeoPreview
              )
            }

            <div className={`star-container ${isHovering && vimeoPreview ? 'fade-out' : ''}`}>
              <Star color={color} />
            </div>

            {cardItemType === 'playlist' && (
              <div className="video-card-count-container">
                <img src={`${process.env.PUBLIC_URL}/images/icon-list-to-play.svg`} alt="open-playlist-button" />
                {formatNumber(numOfVideos)} {numOfVideos !== 1 ? t('videos') : t('video')}
              </div>
            )}

          </div>
        </div>

        <div className={`title text-${color} mt-2 mb-1`}>
          {title}
        </div>

        {hashtags.length > 0 && (
          <div className="video-card-hashtag-container">
            {hashtags.map((tag, i) => (
              <div className="clickable" key={i}>#{tag.name}</div>
            ))}
          </div>
        )}

        <div className="video-card-button-container">
          {allowShare && (
            <VideoCardButton
              svgSrc={`${process.env.PUBLIC_URL}/images/icon-share.svg`}
              text={t('button.share')}
              alt={'share-button'}
            />
          )}
          {allowDelete && (
            <VideoCardButton
              svgSrc={`${process.env.PUBLIC_URL}/images/icon-delete.svg`}
              text={t('button.delete')}
              alt={'delete-button'}
              handleClick={handleDeleteClick}
            />
          )}
          {allowManage && (
            <VideoCardButton
              icon={faGears}
              text={'Manage'}
              alt={'manage-button'}
              handleClick={loadPlaylist}
            />
          )}
        </div>
      </div>
      <Dialog
        open={openConfirmDelete}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle sx={{ m: 0, p: 4 }} id="alert-dialog-title">
          {"Are you sure you want to delete it?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ m: 2 }} >
          <Button onClick={confirmDelete}>{t('Delete')}</Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const mapStateToProps = (state: AppState) => ({
  viewingPlaylist: state.viewingPlaylist
});

const mapDispatchToProps = (dispatch: any) => ({
  setViewingPlaylist: (value: Playlist) => dispatch(setViewingPlaylist(value)),
  setProfilePlaylistMode: (value: ProfilePlaylistMode) => dispatch(setProfilePlaylistMode(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VideoCard);
