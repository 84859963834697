import { useTranslation } from "react-i18next";
import "./NotFound.scss";
import { randomAsciiArt } from "../utils/shared";

const NotFound: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div id="page-not-found">
      <div className="title">{ randomAsciiArt }</div>
      <div className="subtitle">{t('not_found_error')}</div>
      <a className="btn btn-primary text-white" href="/">
        {t('button.back_to_home')}
      </a>
    </div>
  );
};

export default NotFound;