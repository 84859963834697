import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Pagination.scss";
import { faAnglesLeft, faAnglesRight, faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Dispatch, SetStateAction, useState } from "react";
import GoToPagePopover from "./GoToPagePopover";
import React from "react";

type Page = number | string;

export interface PaginationProps {
  videosPerPage: number,
  totalVideoCount: number,
  currentPage: number,
  pageNumsToShow: Array<number>,
  setCurrentPage: Dispatch<SetStateAction<number>>;
  color: string;
}
const Pagination: React.FC<PaginationProps> = ({
  videosPerPage,
  totalVideoCount,
  currentPage,
  pageNumsToShow,
  setCurrentPage,
  color,
}) => {
  const [targetPage, setTargetPage] = useState<string>('');
  const [selectedPageIndex, setSelectedPageIndex] = useState<number>(0);
  const [showGoToPage, setShowGoToPage] = useState<boolean>(false);
  const lastPage: Page = pageNumsToShow[pageNumsToShow.length - 1];
  const notFirstPage: boolean = currentPage > 1;
  const notLastPage: boolean = currentPage < lastPage;
  const fromRecordNum: number = (currentPage - 1) * videosPerPage + 1;
  const toRecordNum: number = (currentPage * videosPerPage) > totalVideoCount ? totalVideoCount : currentPage * videosPerPage;
  let previousPage = -1; // For logic that shows "..." between skipped pages
  return lastPage <= 1 ? <></> : (
    <div className="list-pagination" color={color}>
      <div className="list-pagination-summary text-muted me-auto">
        {
          `Displaying ${totalVideoCount > 1 ? 'records' : 'record'} ${fromRecordNum}` +
          (
            fromRecordNum === toRecordNum ? '' : ` - ${toRecordNum}`
          ) +
          ` of ${totalVideoCount}`
        }
      </div>
      <nav className="list-pagination-links loading-indicator-container size-small">
        <ul className="pagination">
          {
            notFirstPage && <>
              {/* double left arrow & left arrow */}
              <li className='page-item' onClick={() => setCurrentPage(1)}>
                <span className="page-arrow page-link page-first">
                  <FontAwesomeIcon className="icon-angle-double-left" icon={faAnglesLeft} />
                </span>
              </li>
              <li className='page-item' onClick={() => notFirstPage && setCurrentPage(currentPage - 1)}>
                <span className="page-arrow page-link page-back">
                  <FontAwesomeIcon className="icon-angle-left" icon={faChevronLeft} />
                </span>
              </li>
            </>
          }

          {/* page chooser */}
          {pageNumsToShow?.map((page: number, i) =>
            <React.Fragment key={i}>
              {
                /* display "..." between skipped pages */
                previousPage > 0 && page !== previousPage + 1 &&
                  // '...' page
                  <li className="page-item" key={`${i}-and-prev`} >
                    <span className="page-link page-dots"
                      onClick={() => {
                        if (selectedPageIndex === i && showGoToPage) {
                          setShowGoToPage(false); // close opening goToPage
                        } else {
                          setShowGoToPage(true); // open closed goToPage
                          setSelectedPageIndex(i);
                        }
                      }}>
                      ...
                    </span>
                    {selectedPageIndex === i && showGoToPage &&
                      <GoToPagePopover
                        setShowGoToPage={setShowGoToPage}
                        targetPage={targetPage}
                        setTargetPage={setTargetPage}
                        setPageCurrent={setCurrentPage}
                        pageLast={lastPage}
                      />
                    }
                  </li>
              }

              <li className={page === currentPage ? "page-item active" : "page-item"} key={i}
                  onClick={() => (page !== currentPage) && setCurrentPage(page)}>
                <span className={page === currentPage ? "page-link page-active" : "page-link"}>
                  {page}
                </span>
              </li>

              {(() => { previousPage = page; return undefined; })()}

            </React.Fragment>
          )}

          {/* double right arrow & right arrow */}
          { notLastPage && <>
            <li className='page-item' onClick={() => setCurrentPage(currentPage + 1)}>
              <span className="page-arrow page-link page-next">
                <FontAwesomeIcon className="icon-angle-right" icon={faChevronRight} />
              </span>
            </li>
            <li className={notLastPage ? 'page-item' : 'page-item disabled'} onClick={() => setCurrentPage(lastPage)}>
              <span className="page-arrow page-link page-last">
                <FontAwesomeIcon className="icon-angle-double-right" icon={faAnglesRight} />
              </span>
            </li>
          </>}

        </ul>
      </nav >
    </div >
  );
}

export default Pagination;