// react
import { FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// component
import VideoCard from "./VideoCard";
import FormDialog from "./FormDialog";
// style and icon
import "./ProfilePlaylists.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faArrowLeft, faPenToSquare, faSave, faReorder } from "@fortawesome/free-solid-svg-icons";
// utils
import {AppState, Playlist, ProfilePlaylistMode, Video} from "../utils/models";
import {
    createPlaylist,
    getMyPlaylists,
    getPlaylist,
    updatePlaylist,
    updatePlaylistVideosOrder
} from "../utils/endpoints";
import { toast } from "react-toastify";
import DragAndDropGallery from "./DragAndDropGallery";
import { connect } from "react-redux";
import { setProfilePlaylistMode, setViewingPlaylist } from "../utils/actions";

type ProfilePlaylistsProps = {
  viewingPlaylist: Playlist | null;
  profilePlaylistMode: ProfilePlaylistMode;
  setViewingPlaylist: (value: Playlist) => void,
  setProfilePlaylistMode: (value: ProfilePlaylistMode) => void,
};

const ProfilePlaylists: React.FC<ProfilePlaylistsProps> = ({ 
  viewingPlaylist,
  profilePlaylistMode,
  setViewingPlaylist,
  setProfilePlaylistMode,
}) => {
    const {t} = useTranslation();
    const [showCreatePlaylistPopup, setShowCreatePlaylistPopup] = useState<boolean>(false);
    const [showRenamePopup, setShowRenamePopup] = useState<boolean>(false);
    const [playlists, setPlaylists] = useState<Playlist[] | null>([]);
    const [videosInLatestOrder, setVideosInLatestOrder] = useState<Video[]>([]); // for reordering

    // Update cardInLatestOrder on change of viewingPlaylist
    useEffect(() => {
        if (viewingPlaylist?.videos) {
            setVideosInLatestOrder(viewingPlaylist?.videos || []);
        }
    }, [viewingPlaylist]);

    const handleFetch = async () => {
        const _playlists = await getMyPlaylists();
        setPlaylists(_playlists);
    }

    const refreshViewingPlaylist = async () => {
        if (viewingPlaylist?.uuid) {
            return getPlaylist(viewingPlaylist?.uuid).then(
                (_playlist) => _playlist && setViewingPlaylist(_playlist)
            );
        } else {
            return Promise.resolve();
        }
    }

    const onSaveNewPlaylist = (event: FormEvent) => {
        event.preventDefault(); // avoid page reload
        const playlistName = (event.target as HTMLFormElement).playlist_name.value;
        if (!playlistName) { // missing input
            toast.error('Name is required.');
        } else {
            createPlaylist({playlistName: playlistName})
                .then(response => {
                    setShowCreatePlaylistPopup(false);
                    handleFetch();
                });
        }
    }

    const onSavePlaylistName = (event: FormEvent) => {
        event.preventDefault(); // avoid page reload
        const playlistName = (event.target as HTMLFormElement).playlist_name.value;
        if (!playlistName) { // missing input
            toast.error('Name is required.');
            return;
        } else {
            updatePlaylist({uuid: viewingPlaylist?.uuid, playlistName: playlistName})
                .then(response => {
                    setShowRenamePopup(false);
                    handleFetch();
                    refreshViewingPlaylist();
                })
        }
    };

    const onSaveVideoOrder = () => {
        if (viewingPlaylist) {
            updatePlaylistVideosOrder(
                viewingPlaylist.uuid,
                (videosInLatestOrder || []).map(video => video.uuid)
            ).then(() => {
                toast.success('Successfully updated playlist video order.');
                refreshViewingPlaylist().then(
                    () => setProfilePlaylistMode('manage')
                );
            });
        }
    };

    useEffect(() => {
        if (profilePlaylistMode === 'playlists') {
            handleFetch();
        }
    }, [profilePlaylistMode]);


  return (
      <div id="profile-playlists">
          {/* header title */}
          <div className="d-flex">
              {/* desktop */}
              <div className="title align-self-end d-none d-md-inline-block">
                  {profilePlaylistMode === 'playlists' && t('my_playlists')}
                  {profilePlaylistMode === 'manage' &&
                      <>
                          {profilePlaylistMode === 'manage' && `Managing playlist "${viewingPlaylist?.title}"`}
                          <FontAwesomeIcon
                              className="clickable"
                              icon={faPenToSquare}
                              onClick={() => setShowRenamePopup(true)}/>
                      </>
                  }
                  {profilePlaylistMode === 'reorder' && `Reordering playlist "${viewingPlaylist?.title}"`}
              </div>
              {/* mobile */}
              <div className="title align-self-end d-md-none">
                  {profilePlaylistMode === 'playlists' && t('my_playlists')}
                  {profilePlaylistMode === 'manage' && `Managing playlist "${viewingPlaylist?.title}"`}
                  {profilePlaylistMode === 'reorder' && `Reordering playlist "${viewingPlaylist?.title}"`}
              </div>

              {/* header right btn */}
              <div className="ms-auto">

                  {/* desktop */}
                  {profilePlaylistMode === 'playlists' &&
                      <button
                          className="btn btn-primary d-none d-md-inline-block"
                          onClick={() => setShowCreatePlaylistPopup(true)}>
                          {t('button.create_new_playlist')}
                      </button>
                  }
                  {profilePlaylistMode === 'manage' &&
                      <>
                          <button
                              className="btn btn-secondary d-none d-md-inline-block ms-2"
                              onClick={() => setProfilePlaylistMode('playlists')}>
                              Back
                          </button>
                          {(viewingPlaylist?.videos?.length || 0) > 1 &&
                              <button
                                  className="btn btn-primary d-none d-md-inline-block ms-2"
                                  onClick={() => setProfilePlaylistMode('reorder')}>
                                  Reorder
                              </button>
                          }
                      </>
                  }
                  {profilePlaylistMode === 'reorder' &&
                      <>
                          <button
                              className="btn btn-secondary d-none d-md-inline-block ms-2"
                              onClick={() => setProfilePlaylistMode('manage')}>
                              Cancel Reordering
                          </button>
                          <button
                              className="btn btn-primary d-none d-md-inline-block ms-2"
                              onClick={onSaveVideoOrder}>
                              Save Order
                          </button>
                      </>
                  }

                  {/* mobile */}
                  {profilePlaylistMode === 'playlists' &&
                      <button
                          className="btn btn-link mx-1 p-0 d-md-none"
                          onClick={() => setShowCreatePlaylistPopup(true)}>
                          <FontAwesomeIcon icon={faAdd}/>
                      </button>
                  }
                  {profilePlaylistMode === 'manage' &&
                      <>
                          <button
                              className="btn btn-link mx-1 p-0 d-md-none"
                              onClick={() => setProfilePlaylistMode('playlists')}>
                              <FontAwesomeIcon icon={faArrowLeft}/>
                          </button>
                          <button
                              className="btn btn-link mx-1 p-0 d-md-none"
                              onClick={() => setShowRenamePopup(true)}>
                              <FontAwesomeIcon className="clickable" icon={faPenToSquare}/>
                          </button>
                          {(viewingPlaylist?.videos?.length || 0) > 1 &&
                              <button
                                  className="btn btn-link mx-1 p-0 d-md-none"
                                  onClick={() => setProfilePlaylistMode('reorder')}>
                                  <FontAwesomeIcon className="clickable" icon={faReorder}/>
                              </button>
                          }
                      </>
                  }
                  {profilePlaylistMode === 'reorder' &&
                      <>
                          <button
                              className="btn btn-link mx-1 p-0 d-md-none"
                              onClick={() => setProfilePlaylistMode('manage')}>
                              <FontAwesomeIcon icon={faArrowLeft}/>
                          </button>
                          <button
                              className="btn btn-link mx-1 p-0 d-md-none"
                              onClick={onSaveVideoOrder}>
                              <FontAwesomeIcon icon={faSave}/>
                          </button>
                      </>
                  }
              </div>
          </div>

          <hr/>

          {/* My playlists */}
          {
              profilePlaylistMode === 'playlists' && (
                  (playlists?.length || 0) <= 0 ?
                      <div>You have not created any playlist yet.</div> :
                      <div className="playlist-container">
                          {playlists?.map((playlist, i) => (
                              <VideoCard
                                  key={playlist.uuid}
                                  apiParam={{
                                      playlistUuid: playlist.uuid,
                                      videoUuid: playlist.cover_video?.uuid
                                  }}
                                  thumbnail={playlist.cover_video?.landscape_thumbnail?.path}
                                  thumbnailPortrait={playlist.cover_video?.portrait_thumbnail?.path}
                                  title={playlist.title}
                                  cardItemType="playlist"
                                  cardClickAction="playAll"
                                  videoCount={playlist.video_uuids?.length || 0}
                                  handleDataRefresh={handleFetch}
                                  allowDelete
                                  allowManage
                              />
                          ))}
                      </div>
              )
          }
          {/* View a particular Playlist */}
          {
              profilePlaylistMode === 'manage' && (
                  (viewingPlaylist?.videos?.length || 0) <= 0 ?
                      <div>{t('playlist_empty')}</div>
                      :
                      <div className="playlist-container">
                          {viewingPlaylist?.videos && viewingPlaylist.videos.length > 0 &&
                              viewingPlaylist.videos.map((v, i) => (
                                  <VideoCard
                                      key={i}
                                      apiParam={{playlistUuid: viewingPlaylist.uuid, videoUuid: v.uuid}}
                                      thumbnail={v?.landscape_thumbnail?.path || undefined}
                                      thumbnailPortrait={v?.portrait_thumbnail?.path || undefined}
                                      color="orange"
                                      title={v.title}
                                      cardItemType="playlistVideo"
                                      playVideoUrl={`/video/${viewingPlaylist.uuid}/${v.uuid}`}
                                      hashtags={v.tags}
                                      date={v.created_at}
                                      allowDelete
                                  />
                              ))}
                      </div>
              )
          }
          {profilePlaylistMode === 'reorder' && (
              (viewingPlaylist?.videos?.length || 0) <= 0 ?
                  <div>{t('playlist_empty')}</div>
                  :
                  <div className="playlist-container">
                      <DragAndDropGallery
                          videos={videosInLatestOrder || []}
                          setVideos={setVideosInLatestOrder}
                          cardType={'videoCard'}
                          cardComponentProp={{
                              viewingPlaylist: viewingPlaylist,
                          }}
                          setViewingPlaylist={setViewingPlaylist}
                      />
                  </div>
          )
          }

          {
              showCreatePlaylistPopup &&
              // Create Playlist dialog
              <FormDialog
                  title={t('new_playlist_name')}
                  placeholder={t('new_playlist_name_placeholder')}
                  submitLabel={t('button.create')}
                  setShowPopup={setShowCreatePlaylistPopup}
                  onSubmit={onSaveNewPlaylist}
              />
          }

          {
              showRenamePopup &&
              // Rename Playlist dialog
              <FormDialog
                  title={t('button.rename')}
                  placeholder={t('new_playlist_name_placeholder')}
                  previousName={viewingPlaylist?.title}
                  submitLabel={t('button.save')}
                  setShowPopup={setShowRenamePopup}
                  onSubmit={onSavePlaylistName}
              />
          }
      </div>
  );
}

const mapStateToProps = (state: AppState) => ({
  viewingPlaylist: state.viewingPlaylist,
  profilePlaylistMode: state.profilePlaylistMode,
});

const mapDispatchToProps = (dispatch: any) => ({
  setViewingPlaylist: (value: Playlist) => dispatch(setViewingPlaylist(value)),
  setProfilePlaylistMode: (value: ProfilePlaylistMode) => dispatch(setProfilePlaylistMode(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePlaylists);
