import { useTranslation } from "react-i18next";
import "./FormDialog.scss";

export interface FormDialogProps {
  children?: React.ReactNode;
  title: string;
  placeholder: string;
  previousName?: string;
  submitLabel: string;
  setShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit: (event: React.FormEvent) => void;
}
const FormDialog: React.FC<FormDialogProps> = ({
  children,
  title,
  placeholder,
  previousName = undefined,
  submitLabel,
  setShowPopup,
  onSubmit
}) => {
  const { t } = useTranslation();

  return (
    <div className="popup">
      <div className="popup-backdrop"></div>
      <form onSubmit={onSubmit}>
        <div className="title">{title}</div>
        <hr />
        {children ||
          <div className="input-container">
            <input
              name="playlist_name"
              defaultValue={previousName}
              type="text"
              placeholder={placeholder}
            />
          </div>
        }
        <div className="pb-3"></div>

        <div className="button-container">
          <button
            type="button"
            className="btn btn-secondary me-md-3"
            onClick={() => setShowPopup(false)}>
            {t('button.cancel')}
          </button>
          <button className="btn btn-primary" type="submit">
            {submitLabel}
          </button>
        </div>
      </form>
    </div>
  );
};

export default FormDialog;