// src/App.js
import './DragAndDropGallery.scss';
import React, {Children, Dispatch, SetStateAction, useCallback, useRef, useState} from "react";
import { useDrag, useDrop } from "react-dnd";
import VideoCard from "./VideoCard";
import {Video} from "../utils/models";

interface DndCardProps {
  data: any;
  id: number;
  index: number;
  cardType: 'default' | 'videoCard';
  moveImage: any;
  cardComponentProp?: any;
  setViewingPlaylist?: any;
}

interface DragAndDropGalleryProps {
  videos: Video[];
  setVideos: Dispatch<SetStateAction<Video[]>>
  cardType: 'default' | 'videoCard';
  cardComponentProp?: any;
  setViewingPlaylist?: any;
}

const DndCard: React.FC<DndCardProps> = ({
  data,
  id,
  index,
  cardType,
  moveImage,
  cardComponentProp,
  setViewingPlaylist
}) => {

  const ref = useRef(null);
  
  const [, drop] = useDrop({
    accept: "image",
    hover: (item: any, monitor: any) => {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = (ref.current as HTMLDivElement)?.getBoundingClientRect();

      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveImage(dragIndex, hoverIndex);

      item.index = hoverIndex;
    }
  });

  const [{ isDragging }, drag] = useDrag({
    type: "image",
    item: () => {
      return { id, index };
    },
    collect: (monitor) => {
      return {
        isDragging: monitor.isDragging()
      };
    }
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    cardType === 'videoCard' ?
      <div ref={ref} style={{ opacity }} className="dnd-card">
        <VideoCard
          style={{width: '100%'}}
          apiParam={{ playlistUuid: cardComponentProp?.viewingPlaylist?.uuid, videoUuid: data.uuid }}
          thumbnail={data?.landscape_thumbnail?.path || undefined}
          thumbnailPortrait={data?.portrait_thumbnail?.path || undefined}
          color="orange"
          title={data.title}
          cardItemType="playlistVideo"
          cardClickAction="playVideo"
          playVideoUrl={`/video/${cardComponentProp?.viewingPlaylist?.uuid}/${data.uuid}`}
          hashtags={data.tags}
          date={data.created_at}
        />
      </div>
      :
      <div ref={ref} style={{ opacity }} className="dnd-card">
        <img src={data.src} alt={data.title} />
      </div>
  );
};

const DragAndDropGallery: React.FC<DragAndDropGalleryProps> = ({
  videos,
  setVideos,
  cardType,
  cardComponentProp,
  setViewingPlaylist
}) => {
  const moveImage = useCallback((dragIndex: number, hoverIndex: number) => {
    setVideos((prevVideos: Video[]) => {
      const clonedCards = [...prevVideos];
      const removedItem = clonedCards.splice(dragIndex, 1)[0];

      clonedCards.splice(hoverIndex, 0, removedItem);
      return clonedCards;
    });
  }, []);

  return (
    <>
      {Children.toArray(
        videos.map((card: any, index: number) => (
          <DndCard
            data={card}
            id={card.id}
            index={index}
            cardType={cardType}
            moveImage={moveImage}
            cardComponentProp={cardComponentProp}
            setViewingPlaylist={setViewingPlaylist}
          />
        ))
      )}
    </>
  );
};

export default DragAndDropGallery;
