// This component is shared and used
// by category and video items display 
// in Channel, Folder, and Home and VideoDetail.tsx

// Import React hooks
import { useState } from "react";

// Import styles and utilities
import "./InfoCard.scss";
import { defaultCardBackground } from "../utils/shared";

// Import FontAwesome icon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

// Import library
import { Link } from "react-router-dom";

export interface InfoCardProps {
  color?: string,
  title?: string,
  description?: string,
  url?: string,
  cardBackground?: string,
  cardBackgroundPortrait?: string,
  vimeoPreview?: any,
  target: string,
}

const InfoCard: React.FC<InfoCardProps> = ({
  color = 'blue',
  title,
  description,
  url = "#",
  cardBackground,
  cardBackgroundPortrait,
  vimeoPreview,
  target,
}) => {
  const [isHovering, setIsHovering] = useState(false);

  if (!cardBackground) {
    cardBackground = defaultCardBackground
  }

  const showVideoPreview = isHovering && !!vimeoPreview;

  return (
    <Link
      className={`info-card ${showVideoPreview ? 'has-video-preview' : ''}`}
      to={url}
      reloadDocument={true}
      target={target}
      color={color}
      onMouseOver={() => setIsHovering(true)}
      onMouseOut={() => setIsHovering(false)}
    >
      <div className="info-card-ratio">
        <div className="info-card-wrapper">
          <img className="info-card-background" alt="info-card-background" src={cardBackground} />
          <img className="info-card-background-portrait" alt="info-card-background" src={cardBackgroundPortrait || cardBackground} />
          <div className="info-card-overlay" />
          <div className="info-card-color-overlay" />
          {
            showVideoPreview && vimeoPreview
          }
          <div className={`info-card-content ${showVideoPreview ? 'fade-out' : ''}`}>
            {!!title && (
              <div className={`info-card-title`}>
                {title}
              </div>
            )}
            {description &&
                <div>
                  {description.split('\n').map((item, i) => (
                    <div className={`info-card-description`} key={i}>
                      {item}
                    </div>
                  ))}
                </div>
            }
          </div>
        </div>
      </div>
    </Link>
  );
}

export default InfoCard;
