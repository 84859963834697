import { Link, } from 'react-router-dom';

import './ColorCard.scss';

export interface ColorCardProps {
  color?: string,
  children: React.ReactNode,
  slug: string,
}

const ColorCard: React.FC<ColorCardProps> = ({
  color = 'blue',
  children,
  slug
}) => {
  return (
    <Link className='color-card' to={`/channel/${slug}`} color={color}>
      {children}
    </Link>
  );
}

export default ColorCard;