import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./BackToTop.scss";
import { useEffect, useState } from "react";

const BackToTop: React.FC = () => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const onBackToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setIsVisible(window.scrollY > 300);
    });
  }, []);

  return (
    <div className={`back-to-top ${isVisible ? 'show' : ''}`}>
      <div className="container">
        <button onClick={() => onBackToTop()}>
          <FontAwesomeIcon icon={faArrowUp} />
        </button>
      </div>
    </div>
  );
}

export default BackToTop;