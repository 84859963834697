// Import React hooks and components
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { connect } from "react-redux";

// Import styles and utilities
import './SearchResult.scss';
import { getSearchVideos } from '../utils/endpoints';
import { mapVideosToPreviews, randomAsciiArt } from '../utils/shared';
import { AppState, Category, Filter, Tag, Video } from "../utils/models";

// Import components
import InfoCard from '../components/InfoCard';
import Pagination from '../components/Pagination';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

type SearchResultProps = {
  channels: Category[] | [];
  tags: Tag[] | [];
};

const SearchResult: React.FC<SearchResultProps> = ({ channels, tags }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { serializedFilter } = useParams();

  const [videos, setVideos] = useState<Video[] | null | undefined>();
  // pagination
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageNumsToShow, setPageNumsToShow] = useState<[]>([]);
  const [totalVideoCount, setTotalVideoCount] = useState<number>(0);
  const [perPage, setPerPage] = useState<number>(0);
  const [filter, setFilter] = useState<Filter>();

  const folderLookUp = (channels: Category[]): { [id: number]: string } => {
    return channels.flatMap((channelItem) =>
      channelItem.children.map((subChannel) => ({
        [subChannel.id]: subChannel.title
      }))
    ).reduce((acc, obj) => ({ ...acc, ...obj }), {});
  };

  const handleFetch = async () => {
    if (!serializedFilter) {
      return;
    }
    let filter: Filter;
    try {
      filter = JSON.parse(serializedFilter);
      if (!filter.page) {
        filter.page = currentPage || 1;
      }
      console.log(filter)
      setFilter(filter);
      const data = await getSearchVideos(filter);
      setVideos(data?.videos);
      setPerPage(data?.per_page);
      setCurrentPage(data?.current_page);
      setPageNumsToShow(data?.pages_arr);
      setTotalVideoCount(data?.total_video_count);
    
    } catch (error) {
      navigate('/error');
      console.error('Exception caught while trying to fetch search results: ', error);
      return;
    }
  }

  const vimeoPreviews = useMemo(() => {
    return mapVideosToPreviews(videos || []);
  }, [videos]);

  useEffect(() => {
    handleFetch();
  }, [serializedFilter]);

  useEffect(() => {
    handleFetch();
  }, [currentPage]);

  const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
  }));

  return videos ? (
    <div id="page-search-result" color={'orange'}>
      <div className="container">
        <div className="my-3 my-md-5 folder-container">
          <div className={`px-3 py-2 bg-orange text-white subtitle me-0 me-md-5 mb-3 mb-md-0`}>
            <b>Search results</b>
          </div>
          <Paper
            sx={{
              boxShadow: 'unset',
              display: 'flex',
              justifyContent: 'start',
              flexWrap: 'wrap',
              listStyle: 'none',
              padding: 0,
              p: 0.5,
              m: 0,
            }}
            component="ul"
          >
            {filter?.keywords && <ListItem >
              <Chip label={filter?.keywords} />
            </ListItem>}
            {filter?.fromDateStr && <ListItem>
              <Chip label={`From ${filter?.fromDateStr}`} />
            </ListItem>}
            {filter?.toDateStr && <ListItem >
              <Chip label={`To ${filter?.toDateStr}`} />
            </ListItem>}
            {channels && filter?.categoryIds?.map((id: number) => (
              <ListItem key={id}>
                <Chip key={id} label={folderLookUp(channels)[id]} />
              </ListItem>
            ))}
            {tags && filter?.tagIds?.map((id: number) => (
              <ListItem key={id}>
                <Chip key={id} label={`#${tags?.find(tag => tag.id === id)?.name}`} />
              </ListItem>
            ))}
          </Paper>
        </div>
      </div>
      <div className="container">
        {videos.length > 0 ?
          <div className="card-container">
            {videos?.map((v, i) => (
              <InfoCard
                key={i}
                color={'orange'}
                description={v.title}
                url={v.external_url || `/video/${v.uuid}`} // go to video detail
                target={v.external_url ? '_blank' : '_self'}
                cardBackground={v.landscape_thumbnail?.path}
                cardBackgroundPortrait={v.portrait_thumbnail?.path}
                vimeoPreview={vimeoPreviews.get(v.uuid)}
              />
            ))}
          </div>
          :
          <div id="no-results-found" className="container">
            <div className="title">{randomAsciiArt}</div>
            <div className="subtitle">{t('videos_empty')}</div>
          </div>
        }
        <Pagination
          totalVideoCount={totalVideoCount}
          videosPerPage={perPage}
          currentPage={currentPage}
          pageNumsToShow={pageNumsToShow}
          setCurrentPage={setCurrentPage}
          color="orange"
        />
      </div>
    </div>
  )
    :
    <>

    </>;
}

const mapStateToProps = (state: AppState) => ({
  channels: state.channels,
  tags: state.tags,
});

export default connect(mapStateToProps)(SearchResult);