// Import React hooks and components
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Import styles and utilities
import './Folder.scss';
import { getChannelInfo } from '../utils/endpoints';
import { mapVideosToPreviews } from '../utils/shared';

// Import components
import Banner from '../components/Banner';
import InfoCard from '../components/InfoCard';

// Import FontAwesome icons
import { Category, Video } from "../utils/models";
import Pagination from '../components/Pagination';
import { Helmet } from 'react-helmet';

const Folder: React.FC = () => {
  const { t } = useTranslation();
  const { folderSlug } = useParams();
  const [folder, setFolder] = useState<Category>();
  const [videos, setVideos] = useState<Video[]>();
  let defaultSubChannelBanner = `/images/banner/sub-channels/${folder?.parent.slug}.jpg`;
  // pagination
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageNumsToShow, setPageNumsToShow] = useState<[]>([]);
  const [totalVideoCount, setTotalVideoCount] = useState<number>(0);
  const [videosPerPage, setVideosPerPage] = useState<number>(0);

  const handleFetch = async () => {
    if (!folderSlug) return;
    const _channel = await getChannelInfo(folderSlug, currentPage);
    if (_channel) {
      setFolder(_channel);
      document.title = [
        _channel?.alternative_title || '',
        _channel?.title || '',
        'EdFlix'
      ].filter(Boolean).join(' | ');
    }
  }

  const vimeoPreviews = useMemo(() => {
    return mapVideosToPreviews(folder?.videos || []);
  }, [folder]);

  useEffect(() => {
    handleFetch();
  }, []);

  useEffect(() => {
    handleFetch();
  }, [currentPage]);


  useEffect(() => {
    if (folder?.pagination) {
      setVideos(folder?.pagination.videos);
      setCurrentPage(folder?.pagination.current_page);
      setPageNumsToShow(folder?.pagination.pages_arr);
      setTotalVideoCount(folder?.pagination.total_video_count);
      setVideosPerPage(folder?.pagination.per_page);
    }
  }, [folder]);

  return folder ? (
    <div id="page-sub-channel" color={folder.color}>
      <Helmet>
        <title>{document.title}</title>
        <meta name="description" content={`${document.title} ${folder?.subtitle} ${folder?.description}`} />
        <meta property="og:title" content={folder?.title} />
      </Helmet>
      <Banner className='sub-page-banner'
        img={folder.banner || folder.parent.banner || defaultSubChannelBanner}>
        <div className={`text-container text-${folder.color}`}>
          <div className='banner-title'>
            <span className='d-inline-block me-3'>{folder.parent?.parent?.title}</span>
            <br />
            <b>{folder.parent?.parent?.subtitle}</b>
          </div>

          <div className="banner-subtitle">
            <b>{folder.parent?.title}</b> <small>{folder.parent?.subtitle}</small>
          </div>
        </div>
      </Banner>

      <div className="container">
        <div className="my-3 my-md-5 folder-container">
          <div className={`px-5 py-2 bg-${folder.color} text-white subtitle me-0 me-md-5 mb-3 mb-md-0`}>
            <b>
              {folder.alternative_title} {folder.title}
            </b>
          </div>
          <div className="description">
            {folder.subtitle && <div><b>{folder.subtitle}</b></div>}
            <div>{folder.description}</div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="card-container">
          {videos?.map((v, i) => (
            <InfoCard
              key={i}
              color={folder.color}
              description={v.title}
              url={v.external_url || `/video/${v.uuid}`} // go to video detail
              target={v.external_url ? '_blank' : '_self'}
              cardBackground={v.landscape_thumbnail?.path}
              cardBackgroundPortrait={v.portrait_thumbnail?.path}
              vimeoPreview={vimeoPreviews.get(v.uuid)}
            />
          ))}
        </div>
        <Pagination
          totalVideoCount={totalVideoCount}
          videosPerPage={videosPerPage}
          currentPage={currentPage}
          pageNumsToShow={pageNumsToShow}
          setCurrentPage={setCurrentPage}
          color={folder.color}
        />
      </div>
    </div>
  ) : <></>;
}

export default Folder;
