import "./ProfilePicture.scss";

export interface ProfilePictureProps {
  src?: string,
}

const ProfilePicture: React.FC<ProfilePictureProps> = ({ src }) => {
  return (
    <div className="profile-picture">
      <img src={src || `${process.env.PUBLIC_URL}/images/user.png`} />
    </div>
  );
}

export default ProfilePicture;