import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./VideoCardButton.scss";

export interface VideoCardButtonProps {
  svgSrc?: string;
  icon?: any;
  text: string;
  alt: string;
  handleClick?: any;
}
const VideoCardButton: React.FC<VideoCardButtonProps> = ({
  svgSrc,
  icon,
  text,
  alt,
  handleClick
}) => {
  return (
    <button onClick={handleClick}>
      {svgSrc && <img src={svgSrc} alt={alt} />}
      {icon && <FontAwesomeIcon icon={icon} />}
      <span>{text}</span>
    </button>
  );
};

export default VideoCardButton;