import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Star.scss";

export interface StarProps {
  color?: string;
  reverse?: boolean;
}

const Star: React.FC<StarProps> = ({ color, reverse }) => {
  return (
    <div className={`star bg-${reverse ? 'white' : color}`}>
      <FontAwesomeIcon icon={faStar} color={reverse ? color: 'white'} />
    </div>
  );
}

export default Star;