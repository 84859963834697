import { defaultBanner } from '../utils/shared';
import './Banner.scss';

export interface BannerProps {
  img: string,
  children?: React.ReactNode,
  className?: string,
}

const Banner: React.FC<BannerProps> = ({ img, children, className }) => {
  return (
    <div className={`banner ${className || ''}`}>
    {/* banner image */}
    <img src={img || defaultBanner } alt="Banner" />
  
    {/* banner text */}
      <div className="wrapper">
        <div className="container">
          {children}
        </div>
      </div>
    </div>
  );
}

export default Banner;