import { BehaviorSubject } from 'rxjs';
import Vimeo from "@u-wave/react-vimeo";
import { AppState, Category, UserProfile, Video } from './models';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { getMyPlaylists, logout } from "./endpoints";

export const initialState: AppState = {
  currentUser: null,
  myPlaylists: [],
  viewingPlaylist: null,
  profilePlaylistMode: 'playlists',
  channels: [],
  tags: [],
};

const asciiArtChoices = [
  '¯\\_(ツ)_/¯',
  '(｡>﹏<)',
  '(,,>﹏<,,)',
  '(╥_╥)',
];
export const randomAsciiArt = asciiArtChoices[Math.floor(Math.random() * asciiArtChoices.length)];

export const userProfile = new BehaviorSubject<UserProfile | null>(null); //

export const commonSwiperSettings = {
  spaceBetween: 8,
  slidesPerView: 3.2,
  breakpoints: {
    769: { slidesPerView: 4.2 },
  }
};

export const formatDate = (date?: string): string => {
  return !!date ? moment(date).format('DD/MM/YYYY HH:mm') : '';
}

export const formatNumber = (num: number): string => {
  return Intl.NumberFormat('en-US').format(num);
}

export const defaultBanner: string = `/images/home-banner.png`;
export const defaultCardBackground: string = `${process.env.PUBLIC_URL}/images/video-bg.png`;

export type OutletContextType = {
    channels: Category[];
}

export const handleMyPlaylistFetch = async (setMyPlaylists: any) => {
  const _myPlaylists = await getMyPlaylists();
  setMyPlaylists(_myPlaylists);
}

export const mapVideosToPreviews = (videos: Video[]): Map<string, any> => {
  let map = new Map<string, any>();
  videos.forEach((video) => {
    if (video.whitelisted_vimeo_metadata?.link) {
      map.set(
          video.uuid,
          <div className={`vimeo-player-hover clickable`}>
            <Vimeo
                video={video.whitelisted_vimeo_metadata.link}
                autoplay={true}
                controls={false}
                muted={true}
                quality={"360p"}
            />
          </div>
      );
    }
  });

  return map;
};

export const useClearStorage = () => {
  const dispatch = useDispatch();

  return () => {
    logout();
    dispatch({ type: 'RESET_STATE' }); // clear Redux store
    localStorage.clear();
  };
};
