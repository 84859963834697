import axios from "axios";
import {toast} from "react-toastify";

interface ApiOptions {
  isPublic?: boolean, // default false
  silent?: boolean,
  errorMessage?: string, // custom error message, overriding response message
  overrideErrorHandler?: (error: any) => any,
}

export const getResponseErrorMessage = (error: any): string => {
  return error.response?.data
    ? (
      typeof error.response.data == 'string'
        ? error.response.data
        : (
          typeof error.response.data.error == 'string'
            ? error.response.data.error
            : (error.response.data.error.message || error.message)
        )
    )
    : error.message;
}

export const api = (options?: ApiOptions) => {
  // Create client
  const client = axios.create({
    baseURL: process.env.REACT_APP_API,
    withCredentials: true,
  });

  // Error handling
  client.interceptors.response.use(
    res => res,
    error => {
      if (!!options?.overrideErrorHandler) {
        return options.overrideErrorHandler(error);
      }

      if (error.request.responseURL.includes('logout') && error.response.status === 403) {
        return;
      }

      if (error.response?.status === 404) {
        window.location.href = `/error`;
        return;
      }

      if (error.response?.status === 403) {
        toast.error('Please login first.');
        return;
      }

      const errorMsg = options?.errorMessage || getResponseErrorMessage(error);
      if (options?.silent) {
        console.error(`An error has occurred during an API call: ${errorMsg}.`);
      } else {
        toast.error(errorMsg);
      }

      return { data: null };
    }
  );

  return client;
}