import React, { Dispatch, SetStateAction } from 'react';
import "./GoToPagePopover.scss";
import {toast} from "react-toastify";

type Page = number | string;

export interface GoToPagePopoverProps {
  setShowGoToPage: Dispatch<SetStateAction<boolean>>;
  targetPage: string;
  setTargetPage: Dispatch<SetStateAction<string>>;
  pageLast: Page,
  setPageCurrent: Dispatch<SetStateAction<number>>;
}
const GoToPagePopover: React.FC<GoToPagePopoverProps> = ({
  setShowGoToPage,
  pageLast,
  targetPage,
  setTargetPage,
  setPageCurrent
}) => {

  const goToPage = () => {
    setShowGoToPage(false);
    if (!targetPage || targetPage > pageLast) {
      toast.error('Invalid page.');
      return
    }
    setPageCurrent(parseInt(targetPage));
  }

  const handleKeyDown = (event: any) => {
    // Allow using Enter key to search
    if (event.keyCode === 13) {
      goToPage();
      return;
    }
    const blockedKeyCodes = [69, 189, 190, 187]; // Key codes for "e", "-", ".", and "+"
    if (blockedKeyCodes.includes(event.keyCode)) {
      event.preventDefault();
    }
  };

  return (
    <div className="go-to-page-popover popover">
      <div className="popover-label">
        Go to page
      </div>
      <input
        type="number"
        pattern="[0-9]"
        value={targetPage}
        min={1}
        onKeyDown={handleKeyDown}
        onPaste={(e) => e.preventDefault()}
        onChange={(e) => setTargetPage(e.target.value)}
      />
      <button onClick={goToPage}>
        Go
      </button>
    </div>
  );
};

export default GoToPagePopover;